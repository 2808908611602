import { combineReducers } from "redux";
import employerReducer from "./employer-reducers";
import userReducer from "./User-reducers";

const allReducers = combineReducers({
  employers: employerReducer,
  user: userReducer
});

export default allReducers;
