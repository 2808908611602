import React from "react";
import MUIDataTable from "mui-datatables";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// Styles
import {
	cardTitle,
	warningColor,
	infoColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import Mytheme from "../CustomStyles/customMuithemeTable";
// Date formater
var dateFormat = require("dateformat");
const styles = {
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px"
	},
	viewiconbutton: {
		color: infoColor,
		padding: "0px",
		backgroundColor: "#ffffff00",
		"&:hover": {
			backgroundColor: "#eeeeee"
		}
	},
	editiconbutton: {
		color: warningColor,
		padding: "0px",
		backgroundColor: "#ffffff00",
		"&:hover": {
			backgroundColor: "#eeeeee"
		}
	}
};

class ViewEmployeelogs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			systemlog_tabledata: [],

			show_activity_log: true
		};
	}
	componentDidCatch(error, info) {
		if (
			error.toString().includes("greater than the total available page length")
		) {
			this.setState({ page: 0 });
		} else {
			this.setState({ hashError: true });
		}
	}
	componentDidMount() {
		var systemlog_tabledata = [];
		var self = this;
		var employer_log = this.props.EmployerActivity.logs;
		//for grid view element should be in same order column and the data pushed here should be same count.
		Object.keys(employer_log).forEach(function(key) {
			systemlog_tabledata.push([
				//6
				employer_log[key].module_name,
				employer_log[key].username,
				employer_log[key].operation,
				dateFormat(
					new Date(employer_log[key].timestamp),
					"dd/mm/yyyy HH:MM:ss"
				),
				// employer_log[key].timestamp,
				employer_log[key].browser === null ? "-" : employer_log[key].browser,
				employer_log[key].ip === null ? "-" : employer_log[key].ip
			]);
		});

		self.setState({
			systemlog_tabledata: systemlog_tabledata
		});
	}
	render() {
		const { classes } = this.props;
		const options = {
			filterType: "dropdown",
			responsive: "stacked",
			fixedHeader: true,
			page: this.state.page,
			selectableRows: false
		};
		const columns = [
			{
				name: "Module Name",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Username",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Activity",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Performed On",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Browser",
				options: {
					filter: true,
					sort: true,
					display: false
				}
			},
			{
				name: "IP Address",
				options: {
					filter: true,
					sort: true
				}
			}
		];
		return (
			<GridContainer>
				<GridItem xs={12}>
					{this.state.show_activity_log ? (
						<Card>
							<CardHeader color="info" icon>
								<CardIcon color="info">
									<Icon>show_chart</Icon>
								</CardIcon>
								<h4 className={classes.cardIconTitle}>Activity Log</h4>
							</CardHeader>
							<CardBody>
								<MuiThemeProvider theme={Mytheme}>
									<MUIDataTable
										title={""}
										data={this.state.systemlog_tabledata}
										columns={columns}
										options={options}
									/>
								</MuiThemeProvider>
							</CardBody>
						</Card>
					) : null}
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(styles)(ViewEmployeelogs);
