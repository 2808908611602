const baseurl = "https://devapi.verifymate.app";
const platform = "/admin";
const s3Platform = "";
const profilePlatformEmployee = "/employee";
const profilePlatformEmployer = "/employer";
const dev_env = "";
const prod_env = "/prod";
const release_env = "/release";

const dev = {
	s3: {
		s3Download: baseurl + dev_env + s3Platform + "/employee/retrieveFile"
	},
	siteKey: "6LeCepsUAAAAAPJoAWAG97Nwosc_KukG-8syoZoj",
	graphqlendpoint: {
		URL: baseurl + dev_env + platform + "/graphql"
	},

	profileImage: {
		EmployeeProfileRetrieve:
			baseurl + dev_env + profilePlatformEmployee + "/retrieveProfileImage",
		EmployerProfileRetrieve:
			baseurl + dev_env + profilePlatformEmployer + "/retrieveProfileImage"
	},
	Cognito: {
		signin_url: baseurl + dev_env + platform + "/signIn",
		resendcode_url: baseurl + dev_env + platform + "/resendConfirmationCode",
		changepassword_url: baseurl + dev_env + platform + "/changePassword",
		changePasswordChallenge_url:
			baseurl + dev_env + platform + "/changePasswordChallenge",
		signout_url: baseurl + dev_env + platform + "/signOut",
		forgetpassword_url: baseurl + dev_env + platform + "/forgotPassword",
		confirmForgotPassword_url:
			baseurl + dev_env + platform + "/confirmForgotPassword",
		Employee_profile_update_url:
			baseurl + dev_env + platform + "/adminUpdateAttributesForEmployee",
		Employer_profile_update_url:
			baseurl + dev_env + platform + "/adminUpdateAttributesForEmployer",
		Employee_action: baseurl + dev_env + platform + "/employeeProfileAction",
		Employer_action: baseurl + dev_env + platform + "/employerProfileAction",
		unverify_Employee:
			baseurl + dev_env + platform + "/adminResetAadharForEmployee",
		unverify_Employer:
			baseurl + dev_env + platform + "/adminResetAadharForEmployer"
	}
};
const release = {
	s3: {
		s3Download: baseurl + release_env + s3Platform + "/retrieveFileFromS3"
	},

	graphqlendpoint: {
		URL: baseurl + release_env + platform + "/graphql"
	},
	profileImage: {
		EmployeeProfileRetrieve:
			baseurl + release_env + profilePlatformEmployee + "/retrieve",
		EmployerProfileRetrieve:
			baseurl + release_env + profilePlatformEmployer + "/retrieve"
	},
	Cognito: {
		signin_url: baseurl + release_env + platform + "/signIn",
		resendcode_url:
			baseurl + release_env + platform + "/resendConfirmationCode",

		changepassword_url: baseurl + release_env + platform + "/changePassword",
		changePasswordChallenge_url:
			baseurl + release_env + platform + "/changePasswordChallenge",
		signout_url: baseurl + release_env + platform + "/signOut",
		forgetpassword_url: baseurl + release_env + platform + "/forgotPassword",
		confirmForgotPassword_url:
			baseurl + release_env + platform + "/confirmForgotPassword",
		Employee_profile_update_url:
			baseurl + release_env + platform + "/adminUpdateAttributesForEmployee",
		Employer_profile_update_url:
			baseurl + release_env + platform + "/adminUpdateAttributesForEmployer",
		Employee_action:
			baseurl + release_env + platform + "/employeeProfileAction",
		Employer_action: baseurl + release_env + platform + "/employerProfileAction"
	}
};
const prod = {
	s3: {
		s3Download: baseurl + prod_env + s3Platform + "/retrieveFileFromS3"
	},
	profileImage: {
		EmployeeProfileRetrieve:
			baseurl + prod_env + profilePlatformEmployee + "/retrieve",
		EmployerProfileRetrieve:
			baseurl + prod_env + profilePlatformEmployer + "/retrieve"
	},

	graphqlendpoint: {
		URL: baseurl + prod_env + platform + "/graphql"
	},
	Cognito: {
		signin_url: baseurl + prod_env + platform + "/signIn",
		resendcode_url: baseurl + prod_env + platform + "/resendConfirmationCode",
		changepassword_url: baseurl + prod_env + platform + "/changePassword",
		changePasswordChallenge_url:
			baseurl + prod_env + platform + "/changePasswordChallenge",
		signout_url: baseurl + prod_env + platform + "/signOut",
		forgetpassword_url: baseurl + prod_env + platform + "/forgotPassword",
		confirmForgotPassword_url:
			baseurl + prod_env + platform + "/confirmForgotPassword",
		Employee_profile_update_url:
			baseurl + prod_env + platform + "/adminUpdateAttributesForEmployee",
		Employer_profile_update_url:
			baseurl + prod_env + platform + "/adminUpdateAttributesForEmployer",
		Employee_action: baseurl + prod_env + platform + "/employeeProfileAction",
		Employer_action: baseurl + prod_env + platform + "/employerProfileAction"
	}
};

console.log(" process.env.REACT_APP_HOST_ENV", process.env.REACT_APP_HOST_ENV);
const configuration =
	process.env.REACT_APP_HOST_ENV === "prod"
		? prod
		: process.env.REACT_APP_HOST_ENV === "release"
		? release
		: dev;
export default {
	// Add common config values here
	MAX_ATTACHMENT_SIZE: 5000000,
	...configuration
};
