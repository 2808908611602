import { createMuiTheme } from "@material-ui/core/styles";

const Mytheme = createMuiTheme({
	typography: { useNextVariants: true },
	overrides: {
		MuiPaper: {
			root: {
				marginBottom: "30px",
				marginTop: "30px"
			}
		},
		MuiIcon: {
			root: {
				width: "1em",
				height: "1em",
				overflow: "hidden",
				fontSize: "20px",
				userSelect: "none",
				flexShrink: "0"
			}
		},
		MuiTable: {
			root: {
				borderCollapse: "none"
			}
		},
		MUIDataTableSelectCell: {
			fixedHeader: {
				top: "0px !important",
				left: "0px !important",
				zIndex: "100 !important",
				position: "sticky !important",
				backgroundColor: "none !important",
				border: "1px solid black !important"
			}
		},
		MUIDataTableBodyCell: {
			cellStacked: {
				"@media (max-width: 959.95px)": {
					width: "calc(70% - 150px) !important",
					height: "30px ",
					display: "inline-block",
					fontSize: "11px ",
					whiteSpace: "nowrap",
					backgroundColor: "#FFF"
				},
				"@media (max-width: 420.95px)": {
					width: "calc(70% - 100px) !important",
					height: "30px ",
					display: "inline-block",
					fontSize: "11px ",
					whiteSpace: "nowrap",
					backgroundColor: "#FFF"
				}
			},
			responsiveStacked: {
				"@media (max-width: 959.95px)": {
					width: "calc(52%)",
					height: "30px ",
					display: "inline-block",
					fontSize: "10px ",
					whiteSpace: "nowrap",
					maxWidth: "250px",
					overflow: "hidden",
					textOverflow: "ellipsis"
				},
				"@media (max-width: 420.95px)": {
					width: "calc(60%)",
					height: "30px ",
					display: "inline-block",
					fontSize: "10px ",
					whiteSpace: "nowrap",
					overflow: "hidden",
					maxWidth: "150px",
					textOverflow: "ellipsis"
				}
			}
		},
		MuiTablePagination: {
			select: {
				paddingLeft: "0px"
			}
		},
		MuiTableCell: {
			root: {
				display: " table-cell",
				padding: "4px 6px 4px 24px",
				textAlign: "left",
				borderBottom: "1px solid rgba(224, 224, 224, 1)",
				verticalAlign: "inherit"
			},
			head: {
				color: "rgba(0, 0, 0, 0.8)",
				fontSize: "0.85rem",
				fontWeight: "800"
			},
			body: {
				"@media (min-width: 950px)": {
					color: "rgba(0, 0, 0, 0.87)",
					fontSize: "0.8125rem",
					fontWeight: "400",
					maxWidth: "100px",
					overflow: "hidden",
					textOverflow: "ellipsis",
					whiteSpace: "nowrap"
				}
			}
		}
	}
});

export default Mytheme;
