import Dashboard from "../Container/Dashboard/Dashboard.jsx";
import EmployeeList from "../Container/Employees/AllEmployeesQuery.jsx";
import EmployerList from "../Container/Employers/AllEmployers.jsx";
import UnverifiedEmployer from "../Container/UnverifiedEmployer/UnverifiedEmployer";
import Billings from "../Container/Billings/Billings";
import Activities from "../Container/logs/Systemlogs";

//*Future Enhancement*
// import Manageuser from "../Container/User-manangement/Manageuser";
// import User_roles_gridList from "../Container/Roles and Permission/User_roles_gridList";
// import EmployeeBlockeduserQuery from "../Container/BlockedUsers/Employee/EmployeeBlockeduserQuery";
// import BlockedAuthorityListQuery from "../Container/BlockedUsers/Authority/BlockedAuthorityListQuery";
// import Feedback from "../Container/Feedback/Feedback";
// import InvitedEmployeeList from "../Container/EmployeeInvite/InvitedEmployeeList";
// import InvitedEmployerList from "../Container/EmployerInvite/InvitedEmployerList";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import TimeLine from "@material-ui/icons/Timeline";
import FeedbackIcon from "@material-ui/icons/Feedback";
import People from "@material-ui/icons/People";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Business from "@material-ui/icons/Business";
import InsertLink from "@material-ui/icons/InsertLink";
import ActivityLog from "@material-ui/icons/ShowChart";

var authenticatedroutes = [
	{
		path: "/dashboard",
		name: "Dashboard",
		icon: DashboardIcon,
		component: Dashboard
	},
	{
		path: "/employees",
		name: "Employees",
		icon: People,
		component: EmployeeList
	},
	{
		path: "/employers",
		name: "Authorities",
		icon: Business,
		component: EmployerList
	},
	{
		path: "/unverifiedemployers",
		name: "Unverified Authority",
		icon: InsertLink,
		component: UnverifiedEmployer
	},
	{
		collapse: true,
		path: "/inviteusers",
		name: "Invite",
		state: "openInvite",
		icon: PersonAdd,
		views: [
			{
				path: "/inviteusers/employee",
				name: "Invite Employee",
				mini: "IE",
				component: Billings
			},
			{
				path: "/inviteusers/employer",
				name: "Invite Authority",
				mini: "IA",
				component: Billings
			}
		]
	},
	//!
	// {
	//   collapse: true,
	//   path: "/usermanagement",
	//   name: "User Managements",
	//   state: "openUser",
	//   icon: Group,
	//   views: [
	//     {
	//       path: "/usermanagement/manageusers",
	//       name: "Users List",
	//       mini: "UL",
	//       component: Manageuser
	//     },
	//     {
	//       path: "/usermanagement/userpermissions",
	//       name: "Roles and Permissions",
	//       mini: "RP",
	//       component: User_roles_gridList
	//     }
	//   ]
	// },

	//!
	//  {
	// 	collapse: true,
	// 	path: "/blockedusers",
	// 	name: "Blocked Users",
	// 	state: "openblockedUser",
	// 	icon: "fa fa-user-times",
	// 	views: [
	// 		{
	// 			path: "/blockedusers/blockedemployee",
	// 			name: "Blocked Employee",
	// 			mini: "BE",
	// 			component: EmployeeBlockeduserQuery
	// 		},
	// 		{
	// 			path: "/blockedusers/blockedemployer",
	// 			name: "Blocked Authority",
	// 			mini: "BA",
	// 			component: BlockedAuthorityListQuery
	// 		}
	// 	]
	// },
	{
		path: "/Activities",
		name: "Activity",
		icon: ActivityLog,
		component: Activities
	},

	{
		path: "/billings",
		name: "Billings",
		icon: TimeLine,
		component: Billings
	},
	{
		path: "/feedback",
		name: "Feedback",
		icon: FeedbackIcon,
		component: Billings
	},
	{ redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" },
	{ redirect: true, path: "*", pathTo: "/404", name: "NotFound" }
];

export default authenticatedroutes;
