import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// * Apollo client
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
// import { RetryLink } from 'apollo-link-retry';

import config from "../config"; // *End point configuration file

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "Custom-component/Header/Header.jsx";
import Footer from "Custom-component/Footer/Footer.jsx";
import Sidebar from "Custom-component/Sidebar/Sidebar.jsx";
// File imports
import Authenticatedroutes from "../routes/authenticatedroutes.jsx";
import history from "../history";
import image from "assets/img/background.jpg";
import Vlogo from "assets/img/Vlogo.png";
import Changepassword from "./Changepassword.jsx";
import authenticatedlayoutstyle from "./authenticatedlayoutstyle";
import ErrorPage from "../Container/Pages/errorPage.jsx";

//configure the graphql endpoint
const httpLink = createHttpLink({
	uri: config.graphqlendpoint.URL
});

//configure the auth token
const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem("accessToken");
	return {
		headers: {
			...headers,
			Authorization: token ? ` ${token}` : ""
		}
	};
});

//handling graphql or network error for the whole application
const errorLink = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		if (graphQLErrors) {
			graphQLErrors.map(({ message, locations, path }) =>
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				)
			);
		}
		if (networkError) {
			console.log(`[Network error]: ${networkError}`);
			if (`${networkError}`.includes("401")) {
				localStorage.setItem("userlogged", false);

				history.push({
					pathname: "/auth/login",
					state: window.location.href
				});
			}
			// const link = new RetryLink({
			// 	attempts: (count, operation, error) => {
			// 		return !!error && operation.operationName !== 'specialCase';
			// 	},
			// 	delay: (count, operation, error) => {
			// 		return count * 1000 * Math.random();
			// 	},
			// });
		}
	}
);

//composing all configuartion in link using apollolink
const link = ApolloLink.from([authLink, errorLink, httpLink]);

//creating apollo client instance
const client = new ApolloClient({
	link,
	cache: new InMemoryCache()
});

const switchRoutes = (
	<Switch>
		{Authenticatedroutes.map((prop, key) => {
			if (prop.redirect)
				return <Redirect from={prop.path} to={prop.pathTo} key={key} exact />;
			if (prop.collapse) {
				return prop.views.map((prop, key) => {
					return (
						<Route path={prop.path} component={prop.component} key={key} />
					);
				});
			}

			return (
				<Route path={prop.path} component={prop.component} key={key} exact />
			);
		})}
	</Switch>
);

var ps;

class Authenticatedlayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mobileOpen: false,
			miniActive: false,
			noticeModal: false,
			haserror: false,
			employer_aadhaar_number: "",
			employer_aadhaar_numberState: "",
			showaadhaarnumber: true,
			showOTP: false,
			showChapgepasswordModal: false,
			logged:
				localStorage.getItem("userlogged") && localStorage.getItem("username")
		};
		this.resizeFunction = this.resizeFunction.bind(this);
		this.close = this.close.bind(this);
		this.changepasswordModalClick = this.changepasswordModalClick.bind(this);
	}
	changepasswordModalClick() {
		this.setState({ showChapgepasswordModal: true });
	}
	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.refs.mainPanel, {
				suppressScrollX: true,
				suppressScrollY: false
			});
			document.body.style.overflow = "hidden";
		}
		window.addEventListener("resize", this.resizeFunction);
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
		window.removeEventListener("resize", this.resizeFunction);
	}
	componentDidUpdate(e) {
		if (e.history.location.pathname !== e.location.pathname) {
			this.refs.mainPanel.scrollTop = 0;
			if (this.state.mobileOpen) {
				this.setState({ mobileOpen: false });
			}
		}
	}
	componentDidCatch() {
		this.setState({ haserror: true });
	}
	// close function for change password field
	close(value) {
		this.setState({ showChapgepasswordModal: value });
	}
	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};
	handleBack = () => {
		this.setState({ noticeModal: !this.state.noticeModal });
	};
	handleOTP = () => {
		this.setState({ showaadhaarnumber: false, showOTP: true });
	};
	getRoute() {
		return this.props.location.pathname !== "/maps/full-screen-maps";
	}
	sidebarMinimize() {
		this.setState({ miniActive: !this.state.miniActive });
	}
	resizeFunction() {
		if (window.innerWidth >= 960) {
			this.setState({ mobileOpen: false });
		}
	}
	// handle user logout
	handleLogout = async event => {
		localStorage.clear();
		this.props.history.push("/auth/login");
	};
	render() {
		const { classes, ...rest } = this.props;
		const mainPanel =
			classes.mainPanel +
			" " +
			cx({
				[classes.mainPanelSidebarMini]: this.state.miniActive,
				[classes.mainPanelWithPerfectScrollbar]:
					navigator.platform.indexOf("Win") > -1
			});
		if (this.state.haserror) {
			return (
				<div>
					<ErrorPage />
				</div>
			);
		} else {
			return (
				<div className={classes.wrapper}>
					{this.state.logged ? (
						<ApolloProvider client={client}>
							<Sidebar
								changepasswordclick={this.changepasswordModalClick}
								handleBack={this.handleBack}
								handleLogout={this.handleLogout}
								routes={Authenticatedroutes}
								// logoText={"VERIFY MATE"}
								logo={Vlogo}
								image={image}
								handleDrawerToggle={this.handleDrawerToggle}
								open={this.state.mobileOpen}
								color="blue"
								bgColor="black"
								client={client}
								miniActive={this.state.miniActive}
								{...rest}
							/>
							<div className={mainPanel} ref="mainPanel">
								<Header
									sidebarMinimize={this.sidebarMinimize.bind(this)}
									miniActive={this.state.miniActive}
									routes={Authenticatedroutes}
									handleDrawerToggle={this.handleDrawerToggle}
									client={client}
									{...rest}
								/>
								{/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
								{this.getRoute() ? (
									<div className={classes.content}>
										<div className={classes.container}>{switchRoutes}</div>
									</div>
								) : (
									<div className={classes.map}>{switchRoutes}</div>
								)}
								{this.getRoute() ? <Footer fluid /> : null}
							</div>
						</ApolloProvider>
					) : (
						<div>
							<Redirect to="/auth/login" />
						</div>
					)}

					<div>
						{this.state.showChapgepasswordModal ? (
							<Changepassword close={this.close} />
						) : null}
					</div>
				</div>
			);
		}
	}
}

Authenticatedlayout.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(authenticatedlayoutstyle)(Authenticatedlayout);
