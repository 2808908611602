import React from "react";
import { graphql } from "react-apollo";
import axios from "axios";
import PropTypes from "prop-types";
import Gravatar from "react-gravatar";
// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Avatar from "@material-ui/core/Avatar";

import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// Custom components
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import LoaderButton from "../../../Custom-component/LoaderButton";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
// Styles
import RevokemodelStyle from "../../CustomStyles/RevokemodelStyle";
// Queries and mutation
import BlockEmployer from "../../../Graphql/Mutation/BlockEmployer";
// validations
import validation from "../../Validation/ProfileValidation.jsx";
// File imports
import config from "../../../config";
function Transition(props) {
	return <Slide direction="down" {...props} />;
}

class AuthorityUnblock extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showunblockuser: true,
			alert: null,
			unrevokeModal: true,
			isLoading: false,
			admin_unblockemployer_reason: "",
			admin_unblockemployer_reasonState: ""
		};
	}
	// modal close button onclick function
	handleClose = () => {
		this.setState({
			alert: null,
			admin_unblockemployer_reason: "",
			admin_unblockemployer_reasonState: "",
			admin_unblockemployer_reasonErrMsg: ""
		});
	};

	handleUnRevokeClose = modelname => {
		this.setState({ [modelname]: false });

		this.props.handleback("close");
	};
	// onchange function for input fields
	block_reason_change(event, stateName, type) {
		switch (type) {
			case "textarea":
				var reason_authority_validation = validation.verifyTextarea(
					event.target.value,
					stateName
				);
				if (!reason_authority_validation[stateName]) {
					this.setState({ [stateName + "State"]: "success" });
				} else {
					this.setState({
						[stateName + "State"]: "error",
						[stateName + "ErrMsg"]: reason_authority_validation[stateName]
					});
				}
				break;

			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	}

	// validate the inputs on submit
	validate = () => {
		if (
			this.state.admin_unblockemployer_reason !== "" &&
			this.state.admin_unblockemployer_reasonState === "success"
		) {
			return false;
		} else {
			return true;
		}
	};

	handleClosesuccessalert = () => {
		this.setState({ successalert: false });
		this.props.handleback();
	};
	// Function to revoke user
	handleUnblockuser = async event => {
		const { blocked_employers_data } = this.props;
		this.setState({ isLoading: true });
		if (
			this.state.admin_unblockemployer_reason !== "" &&
			this.state.admin_unblockemployer_reasonState === "success"
		) {
			try {
				const Employer_unblock_payload = {
					employer_username: blocked_employers_data.username,
					profile_status: "Active",
					reason: this.state.admin_unblockemployer_reason
				};
				var headers = {
					Authorization: localStorage.getItem("accessToken"),
					"Content-Type": "application/json"
				};
				axios
					.post(config.Cognito.Employer_action, Employer_unblock_payload, {
						headers: headers
					})
					.then(response => {
						if (response.data.data !== null) {
							this.setState({ isLoading: false });
							this.props.handleback("Success", "User Unblocked Successfully");
						} else {
							this.setState({ isLoading: false });
							this.props.handleback(
								"Failed",
								"Failed to Unblock User.Please Try Again."
							);
						}
					})
					.catch(err => {
						this.setState({ isLoading: false });
						this.props.handleback(
							"Failed",
							"Failed to Unblock User.Please Try Again."
						);
					});
			} catch (e) {
				this.setState({ isLoading: false });
				this.props.handleback(
					"Failed",
					"Failed to Unblock User.Please Try Again."
				);
			}
		} else {
			this.setState({
				admin_unblockemployer_reasonState: "error",
				admin_unblockemployer_reasonErrMsg: "Reason is required"
			});
		}
	};
	render() {
		const { classes, blocked_employers_data } = this.props;
		return (
			<div className={classes.wrapper}>
				{this.state.showunblockuser ? (
					<Dialog
						classes={{
							root: classes.center + " " + classes.modalRoot,
							paper: classes.modal
						}}
						open={this.state.unrevokeModal}
						TransitionComponent={Transition}
						keepMounted
						onClose={() => this.handleUnRevokeClose("unrevokeModal")}
						aria-labelledby="notice-modal-slide-title"
						aria-describedby="notice-modal-slide-description"
					>
						<DialogTitle
							id="notice-modal-slide-title"
							disableTypography
							className={classes.modalHeader}
						>
							<Button
								justIcon
								className={classes.modalCloseButton}
								key="close"
								aria-label="Close"
								color="transparent"
								onClick={() => this.handleUnRevokeClose("unrevokeModal")}
							>
								<Close className={classes.modalClose} />
							</Button>
							<h4 className={classes.boldheading}>
								<b>Unblock User</b>
							</h4>
						</DialogTitle>
						<DialogContent
							id="notice-modal-slide-description"
							className={classes.modalBody}
						>
							<GridContainer justify="center">
								<GridItem xs={6} sm={3} md={3}>
									<GridContainer justify="center">
										{/* <Avatar className={classes.revokeuserAvatar}>
											<Gravatar
												email={blocked_employers_data.email}
												default="mp"
												size={120}
											/>
										</Avatar> */}
										<Avatar className={classes.revokeuserAvatar}>
											{/* if employee upload the image */}
											{blocked_employers_data.profile_image_hash &&
											this.props.employee_fileContent ? (
												<img
													src={this.props.employee_fileContent}
													className={classes.avatarImg}
													alt="..."
												/>
											) : (
												<a href="#pablo" onClick={e => e.preventDefault()}>
													<Gravatar
														email={blocked_employers_data.email}
														default="mp"
														size={120}
													/>
												</a>
											)}
										</Avatar>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<br />
							<br />
							<GridContainer justify="flex-start">
								<GridItem xs={6} sm={6} md={6}>
									<p className={classes.mainheading}>
										<b> Username</b>
									</p>
								</GridItem>

								<GridItem xs={6} sm={6} md={6}>
									<p> {blocked_employers_data.username}</p>{" "}
									{/* //!username only given as uniqueid */}
								</GridItem>
							</GridContainer>
							<GridContainer justify="flex-start">
								<GridItem xs={6} sm={6} md={6}>
									<p className={classes.mainheading}>
										<b> Company Name</b>
									</p>
								</GridItem>

								<GridItem xs={6} sm={6} md={6}>
									<p> {blocked_employers_data.company_name}</p>
								</GridItem>
							</GridContainer>
							<GridContainer justify="center">
								<GridItem xs={12} sm={12} md={12}>
									<CustomInput
										success={
											this.state.admin_unblockemployer_reasonState === "success"
										}
										error={
											this.state.admin_unblockemployer_reasonState === "error"
										}
										labelText={<span>Reason to Unblock*</span>}
										id="Admin_Unblockuser_reason"
										formControlProps={{
											fullWidth: true
										}}
										helpText={
											this.state.admin_unblockemployer_reasonState === "error"
												? this.state.admin_unblockemployer_reasonErrMsg
												: null
										}
										inputProps={{
											multiline: true,
											rows: 2,
											onChange: event =>
												this.block_reason_change(
													event,
													"admin_unblockemployer_reason",
													"textarea",
													3
												)
										}}
									/>
								</GridItem>
							</GridContainer>
							<p>Note: Unblock user with valid reason.</p>
						</DialogContent>
						<DialogActions
							className={classes.modalFooter + " " + classes.modalFooterCenter}
						>
							{/* <Button
								onClick={this.handleUnblockuser}
								disabled={this.validate()}
								color="info"
								round
							>
								Unblock
							</Button> */}
							<LoaderButton
								id="unblock_button"
								type="submit"
								onClick={this.handleUnblockuser}
								disabled={this.validate()}
								color="info"
								round
								isLoading={this.state.isLoading}
								text="Unblock"
								loadingText="Processing..."
							/>
						</DialogActions>
					</Dialog>
				) : null}

				{this.state.alert}
			</div>
		);
	}
}

AuthorityUnblock.propTypes = {
	classes: PropTypes.object.isRequired
};

const AuthorityUnblockwithstyle = withStyles(RevokemodelStyle)(
	AuthorityUnblock
);
export default graphql(BlockEmployer)(AuthorityUnblockwithstyle);
