// ##############################
//   authenticatedlayoutstyle
// #############################

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid
} from "assets/jss/material-dashboard-pro-react.jsx";
import { defaultFont } from "assets/jss/material-dashboard-pro-react.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
const RevokemodelStyle = theme => ({
  ...modalStyle(theme),
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  container: { ...containerFluid },
  map: {
    marginTop: "70px"
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  },
  boldheading: {
    color: "#4c4949",
    fontWeight: "600"

  },
  modalheading: {
    color: "#4c4949",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalparagraph: {
    color: "#4c4949",
    fontWeight: "500",
    textAlign: "left"
  },
  mainheading: {
    color: "#4c4949",
    fontWeight: "bolder",
    textAlign: "left"
  },
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: "#3C4858",
    fontSize: "18px"
  },
  cardHeader: {
    zIndex: "3"
  },
  card: {
    minWidth: "300px"
  },
  cardSubtitle: {
    ...defaultFont,
    color: "#999999",
    fontSize: "14px",
    margin: "0 0 10px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  marginRight: {
    marginRight: "5px"
  },
  modalSectionTitle: {
    marginTop: "30px"
  },
  modal: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "500px",
      margin: "1.75rem auto"
    },
    borderRadius: "6px",
    marginTop: "100px !important",
    overflow: "visible",
    maxHeight: "unset",
    position: "relative",
    height: "fit-content",
    minWidth: "500px !important"
  },
  modalBody: {
    paddingTop: "24px !important",
    paddingRight: "24px !important",
    paddingBottom: "16px !important",
    paddingLeft: "24px !important",
    position: "relative !important",
    textAlign: "left !important"
  },
  blueAvatar: {
    textAlign: "center"
  },
  revokeuserAvatar: {
    textAlign: "center",
    width: "100%",
    height: "90%"
  }
});

export default RevokemodelStyle;
