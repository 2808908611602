// ##############################
// // // Header styles
// #############################

import {
	containerFluid,
	defaultFont,
	primaryColor,
	defaultBoxShadow,
	infoColor,
	successColor,
	warningColor,
	dangerColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const headerStyle = theme => ({
	appBar: {
		backgroundColor: "transparent",
		boxShadow: "none",
		borderBottom: "0",
		marginBottom: "0",
		position: "absolute",
		width: "100%",
		paddingTop: "10px",
		zIndex: "1029",
		color: "#555555",
		border: "0",
		borderRadius: "3px",
		padding: "10px 0",
		transition: "all 150ms ease 0s",
		minHeight: "50px",
		display: "block"
	},
	container: {
		...containerFluid,
		minHeight: "50px"
	},
	flex: {
		flex: 1
	},
	notifications: {
		zIndex: "4",
		position: "absolute",
		top: "5px",
		border: "1px solid #FFF",
		right: "5px",
		fontSize: "9px",
		background: dangerColor,
		color: "#FFFFFF",
		minWidth: "16px",
		height: "16px",
		borderRadius: "10px",
		textAlign: "center",
		lineHeight: "14px",
		verticalAlign: "middle",
		display: "block"
	},
	warningNotification: {
		zIndex: "4",
		position: "absolute",
		top: "5px",
		border: "1px solid #FFF",
		right: "5px",
		fontSize: "9px",
		background: warningColor,
		color: "#FFFFFF",
		minWidth: "16px",
		height: "16px",
		borderRadius: "10px",
		textAlign: "center",
		lineHeight: "14px",
		verticalAlign: "middle",
		display: "block"
	},
	title: {
		...defaultFont,
		lineHeight: "30px",
		fontSize: "18px",
		borderRadius: "3px",
		textTransform: "none",
		color: "inherit",
		paddingTop: "0.625rem",
		paddingBottom: "0.625rem",
		margin: "0 !important",
		"&:hover,&:focus": {
			background: "transparent"
		}
	},
	primary: {
		backgroundColor: primaryColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	info: {
		backgroundColor: infoColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	success: {
		backgroundColor: successColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	warning: {
		backgroundColor: warningColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	danger: {
		backgroundColor: dangerColor,
		color: "#FFFFFF",
		...defaultBoxShadow
	},
	sidebarMinimize: {
		float: "left",
		padding: "0 0 0 15px",
		display: "block",
		color: "#555555"
	},
	sidebarMinimizeRTL: {
		padding: "0 15px 0 0 !important"
	},
	sidebarMiniIcon: {
		width: "20px",
		height: "17px"
	},
	dropdown: {
		overflowY: "auto",
		maxHeight: "376px"
	},
	pooperResponsive: {
		zIndex: "1200",
		flex: 1,
		maxWidth: "68%",
		minWidth: "60%",
		display: "flex",
		justifyContent: "flex-end"
	},
	dropdownItem: {
		...defaultFont,
		fontSize: "13px",
		padding: "10px 20px",
		margin: "0 5px",
		borderRadius: "2px",
		position: "relative",
		transition: "all 150ms linear",
		display: "block",
		clear: "both",
		fontWeight: "400",
		height: "100%",
		minWidth: "30%",
		color: "#333",
		whiteSpace: "pre-wrap"
	}
});

export default headerStyle;
