
const initialState = {
    personal:{},
    company:{},
    account:{},
    otpcode:{}
};



const Employer_reducer = (state = initialState,action) =>{
    switch(action.type){
           case "GET_PERSONAL_DATA":
           state={
                   ...state,
                   personal: action.payload
           };
           break;
           case "GET_COMPANY_DATA":
           state={
                   ...state,
                   company: action.payload
           };
           break;
           case "GET_IDENTITY_DATA":
           state={
                   ...state,
                   account: action.payload
           };
           break;
           case "GET_OTP_DATA":
           state={
                   ...state,
                   otpcode: action.payload
           };
           break;
        default:
        state={
                ...state,
              
        };
        break;
    }
    return state;
}                        
export default Employer_reducer;