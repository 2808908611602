import gql from "graphql-tag";
const ListAllEmployeeQuery = gql`
  query employeeQuery($username: String) {
    getAllEmployees(username: $username) {
      employee {
        username
        name
        aadhar_number
        aadhar_verified
        company_name
        designation
        department
        email
        phone_number
        gender
        birthdate
        profile_status
        reason
        address
        created_at
        created_by
        updated_at
        updated_by
        __typename
      }
    }
  }
`;
export default ListAllEmployeeQuery;
