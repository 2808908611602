import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import allReducer from "./reducers";
import logger from "redux-logger";

// const mylogger = (store) => (next) => (action) => {
//   console.log("logged action:",action);
//   next(action);
// }
const composeEnhancers =
	typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ //devtool extension sometimes works in chrome it shows error
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				//to avoid we use this condition
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		  })
		: compose;

const enhancer = composeEnhancers(
	applyMiddleware(thunk, logger)
	// other store enhancers if any
);

// const store = createStore(
//   allReducer,
//   compose(
//     applyMiddleware(thunk, logger),
//     // window.navigator.userAgent.includes('Chrome') ?
//     // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() :
//     // null
//   )
// );
const store = createStore(allReducer, enhancer);
store.subscribe(() => {
	// console.log('store value:', store.getState());
});

export default store;
