import React from "react";
import { Query } from "react-apollo";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// Queries and mutation
import StatisticsCountQuerry from "../../Graphql/Querries/StatisticsCountQuerry";
// Error page
import Errorpage from "../Pages/errorPage";
// File imports
import Dashboardpage from "./Dashboardpage.jsx";
import ScreenLoading from "../LoadingScreen.jsx";

//Employment query
const Dashboardquery = ({
	username = localStorage.getItem("username"),
	history,
	client
}) => (
	<Query query={StatisticsCountQuerry} variables={{ username }}>
		{({ loading, data, error }) => {
			if (loading) {
				return (
					<GridContainer justify="center">
						<GridItem xs={10} sm={8} md={6}>
							<ScreenLoading />
						</GridItem>
					</GridContainer>
				);
			}
			if (error) {
				return (
					<div>
						<Errorpage />
					</div>
				);
			}
			return (
				<div>
					<Dashboardpage
						key={username}
						EmployeeCount={data.employeeCount}
						EmployerCount={data.employerCount}
						BlockedEmployeesCount={data.blockedEmployeesCount}
						BlockedEmployersCount={data.blockedEmployersCount}
						history={history}
						client={client}
					/>
				</div>
			);
		}}
	</Query>
);

export default Dashboardquery;
