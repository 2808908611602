import gql from 'graphql-tag';
const EmployerProfileQuery = gql`
	query EmployerQuery($username: String!, $employer_username: String!) {
		getSingleEmployer(
			username: $username
			employer_username: $employer_username
		) {
			username
			aadhar_number
			name
			aadhar_verified
			email
			phone_number
			PAN
			DIN
			personal_email
			personal_phone_number
			hyperledger_id
			company_name
			company_address
			gender
			birthdate
			profile_status
			profile_image_hash
			reason
			created_at
			created_by
			updated_at
			updated_by
			__typename
		}
		documentCountApprovedByEmployer(
			username: $username
			employer_username: $employer_username
		) {
			count
		}
		employeesCountUnderEmployer(
			username: $username
			employer_username: $employer_username
		) {
			count
		}
		__typename
	}
`;
export default EmployerProfileQuery;
