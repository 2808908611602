import UnauthenticatedLayout from "../layouts/UnauthenticatedLayout";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";

var indexRoutes = [
  {
    path: "/auth",
    name: "UnauthenticatedLayout",
    component: UnauthenticatedLayout
  },
  { path: "/", name: "AuthenticatedLayout", component: AuthenticatedLayout }
];

export default indexRoutes;
