import gql from 'graphql-tag';
const UnverifiedEmployerListQuery = gql`
	query UnverifiedEmployerQuery($username: String!) {
		getAllUnverifiedEmployer(username: $username) {
			UnverifiedEmployer {
				id
				url_id
				company_name
				name
				ip
				created_at
				__typename
			}
			__typename
		}
		__typename
	}
`;
export default UnverifiedEmployerListQuery;
