import {
	dangerColor,
	successColor,
	defaultFont,
	infoColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const customInputStyle = {
	disabled: {
		"&:before": {
			borderColor: "transparent !important"
		}
	},
	underline: {
		"&:hover:not($disabled):before,&:before": {
			borderColor: "#D2D2D2 !important",
			borderWidth: "1px !important"
		},
		"&:after": {
			borderColor: infoColor
		}
	},
	underlineError: {
		"&:after": {
			borderColor: dangerColor
		}
	},
	underlineSuccess: {
		"&:after": {
			borderColor: successColor
		}
	},
	labelRoot: {
		...defaultFont,
		color: "#AAAAAA !important",
		fontWeight: "400",
		fontSize: "14px",
		lineHeight: "1.42857",
		top: "10px",
		"& + $underline": {
			marginTop: "0px"
		}
	},
	labelRootError: {
		color: "#d50000 !important",
		fontSize: "0.8rem",
		lineHeight: "1.3em",
		fontWeight: "bolder"
	},
	labelRootSuccess: {
		color: successColor + " !important"
	},
	formControl: {
		margin: "0 0 17px 0",
		paddingTop: "27px",
		position: "relative",
		"& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
			color: "#495057"
		}
	},
	whiteUnderline: {
		"&:hover:not($disabled):before,&:before": {
			backgroundColor: "#FFFFFF"
		},
		"&:after": {
			backgroundColor: "#FFFFFF"
		}
	},
	input: {
		color: "#495057",
		"&,&::placeholder": {
			fontSize: "14px",
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: "400",
			lineHeight: "1.42857",
			opacity: "1"
		},
		"&::placeholder": {
			color: "#AAAAAA"
		}
	},
	whiteInput: {
		"&,&::placeholder": {
			color: "#FFFFFF",
			opacity: "1"
		}
	},
	feedback: {
		position: "absolute",
		top: "28px !important",
		right: "0",
		zIndex: "2",
		display: "block",
		width: "24px",
		height: "24px",
		textAlign: "center",
		pointerEvents: "none"
	},
	feedbackNoLabel: {
		top: "1px"
	},
	feedbackAdorment: {
		right: "22px"
	}
};

export default customInputStyle;
