// ##############################
// // // LoginPage view styles
// #############################

import {
	container,
	cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const loginPageStyle = theme => ({
	container: {
		...container,
		zIndex: "4",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: "100px"
		}
	},
	cardTitle: {
		...cardTitle,
		color: "#FFFFFF !important",
		fontWeight: "bold !important"
	},
	textCenter: {
		textAlign: "center"
	},
	alignImage: {
		alignItems: "center"
	},
	justifyContentCenter: {
		justifyContent: "center !important"
	},
	customButtonClass: {
		"&,&:focus,&:hover": {
			color: "#FFFFFF "
		},
		marginLeft: "5px",
		marginRight: "5px"
	},
	inputAdornment: {
		marginRight: "18px"
	},
	inputAdornmentIcon: {
		color: "#ffc90d !important"
	},
	cardHidden: {
		opacity: "0",
		transform: "translate3d(0, -60px, 0)"
	},
	cardHeader: {
		marginBottom: "20px"
	},
	socialLine: {
		padding: "0.9375rem 0"
	},
	center: {
		textAlign: "center"
	},
	right: {
		textAlign: "right"
	},
	left: {
		textAlign: "left"
	},
	a: {
		color: "#ffc90d !important"
	},
	...buttonStyle
});

export default loginPageStyle;
