import React, { Suspense } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MUIDataTable from "mui-datatables";
import axios from "axios";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// import Button from "Custom-component/CustomButtons/Button.jsx";

//Imported Files
import DataNotFound from "../DataNotFound";
import EmployeeUnblock from "../BlockedUsers/Employee/EmployeeUnblock";
import ErrorPage from "../Pages/errorPage";
import config from "../../config";
import ScreenLoading from "../LoadingScreen.jsx";

//Graphql Querries and mutations
import EmployeeProfileQuery from "../../Graphql/Querries/EmployeeProfileQuery";
import SingleEmployeeEmploymentQuery from "../../Graphql/Querries/SingleEmployeeEmploymentQuery";
//Styles
import {
	cardTitle,
	warningColor,
	dangerColor,
	infoColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import Mytheme from "../CustomStyles/customMuithemeTable";

// Date formater
var dateFormat = require("dateformat");
const EmployeeProfile = React.lazy(() => import("./EmployeeProfile.jsx"));
const EmployeeRevoke = React.lazy(() => import("./EmployeeRevoke.jsx"));
const EmployeeDocumentList = React.lazy(() =>
	import("./EmployeeDocumentList.jsx")
);

//Custom Page style
const styles = {
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px"
	},
	profileiconbutton: {
		color: infoColor,
		padding: "0px",
		backgroundColor: "#ffffff00",
		"&:hover": {
			backgroundColor: "#eeeeee"
		}
	},
	documenticonbutton: {
		color: warningColor,
		padding: "0px",
		backgroundColor: "#ffffff00",
		"&:hover": {
			backgroundColor: "#eeeeee"
		}
	},
	blockiconbutton: {
		color: dangerColor,
		padding: "0px",
		backgroundColor: "#ffffff00",
		"&:hover": {
			backgroundColor: "#eeeeee"
		}
	}
};

class Myemployee extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingalert: false,
			loadingalert_error: false,
			successalert: false,
			failalert: false,
			employee_profile_data: "",
			employee_data_profile: "",
			employee_employment_data: "",
			employee_tabledata: [],
			show_employee_document_list: false,
			show_allemployee_list: true,
			show_employee_profile: false,
			show_employee_revoke: false,
			show_employee_unblock: false,
			employee_data: [],
			backicon: [],
			message: "",
			profile_status: "Active",
			haserror: false,
			employee_status: !this.props.status
				? ["Active", "Blocked"]
				: [this.props.status],
			employee_fileContent: null
		};
	}

	handleBack = () => {
		this.setState({
			show_allemployee_list: true,
			show_employee_document_list: false,
			show_employee_profile: false,
			show_employee_revoke: false,
			show_employee_unblock: false
		});
	};

	handlebackRevoke = (status, message) => {
		if (status === "Success") {
			this.setState({
				show_employee_revoke: false,
				show_employee_unblock: false,
				successalert: true,
				message: message
			});
		} else if (status === "Failed") {
			this.setState({
				show_employee_revoke: false,
				show_employee_unblock: false,
				failalert: true,
				message: message
			});
		} else {
			this.setState({
				show_employee_revoke: false,
				show_employee_unblock: false
			});
		}
	};
	handleEmployeeProfile = () => {
		this.setState({
			show_allemployee_list: false,
			show_employee_view: false,
			show_employee_profile: true
		});
	};
	componentDidCatch(error, info) {
		if (
			error.toString().includes("greater than the total available page length")
		) {
			this.setState({ page: 0 });
		} else {
			this.setState({ hashError: true });
		}
	}
	componentDidMount() {
		var employee_tabledata = [];
		var self = this;

		const { classes, AllEmployees, client } = this.props;
		var all_employees = AllEmployees.employee;

		//for grid view element should be in same order column and the data pushed here should be same count.
		Object.keys(all_employees).forEach(function(key) {
			employee_tabledata.push([
				all_employees[key].username,
				all_employees[key].name,
				all_employees[key].company_name === null
					? "-"
					: all_employees[key].company_name,
				all_employees[key].email,
				all_employees[key].phone_number,
				dateFormat(new Date(all_employees[key].created_at), "dd/mm/yyyy"),
				all_employees[key].profile_status,
				<div className="actions-right">
					{/* Icon button to view the employment data in details */}
					<Tooltip
						id="tooltip-top"
						title="View Profile"
						placement="bottom"
						classes={{ tooltip: classes.tooltip }}
					>
						<IconButton
							aria-label="Download"
							className={classes.profileiconbutton}
							onClick={async () => {
								self.setState({
									loadingalert: true,
									show_allemployee_list: false,
									loadingalert_error: false
								});
								await client
									.query({
										query: EmployeeProfileQuery,
										variables: {
											username: localStorage.getItem("username"),
											employee_username: all_employees[key].username
										}
									})
									.then(response => {
										if (response.data.getSingleEmployee.profile_image_hash) {
											try {
												//retrieve Employee profile image from s3
												const retrieveProfileData = {
													userName: response.data.getSingleEmployee.username,
													hasedFilename:
														response.data.getSingleEmployee.profile_image_hash
												};
												axios
													.post(
														config.profileImage.EmployeeProfileRetrieve,
														retrieveProfileData
													)
													.then(profileDatabase64 => {
														if (profileDatabase64.data.data) {
															self.setState({
																loadingalert: false,
																// employee_data: all_employees[key],
																show_employee_profile: true,
																show_allemployee_list: false,
																show_employee_view: false,
																show_employee_revoke: false,
																show_employee_unblock: false,
																employee_profile_data:
																	response.data.getSingleEmployee, //set the particular employee data in state variable employee employment data
																employee_fileContent:
																	"data:image/jpeg;base64," +
																	profileDatabase64.data.data.fileContent
															});
														} else {
															self.setState({
																loadingalert: false,
																// employee_data: all_employees[key],
																show_employee_profile: true,
																show_allemployee_list: false,
																show_employee_view: false,
																show_employee_revoke: false,
																show_employee_unblock: false,
																employee_profile_data:
																	response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
															});
														}
													})
													.catch(err => {
														self.setState({
															loadingalert: false,
															// employee_data: all_employees[key],
															show_employee_profile: true,
															show_allemployee_list: false,
															show_employee_view: false,
															show_employee_revoke: false,
															show_employee_unblock: false,
															employee_profile_data:
																response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
														});
													});
											} catch (e) {
												self.setState({
													loadingalert: false,
													// employee_data: all_employees[key],
													show_employee_profile: true,
													show_allemployee_list: false,
													show_employee_view: false,
													show_employee_revoke: false,
													show_employee_unblock: false,
													employee_profile_data: response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
												});
											}
										} else {
											self.setState({
												loadingalert: false,
												// employee_data: all_employees[key],
												show_employee_profile: true,
												show_allemployee_list: false,
												show_employee_view: false,
												show_employee_revoke: false,
												show_employee_unblock: false,
												employee_profile_data: response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
											});
										}
									})
									.catch(err => {
										self.setState({
											loadingalert: false,
											loadingalert_error: true,
											show_allemployee_list: true
										});
									});
							}}
						>
							<Icon>account_circle</Icon>
						</IconButton>
					</Tooltip>{" "}
					&nbsp; &nbsp;
					{/* Icon button to edit the employee exit process for current employee*/}
					<Tooltip
						id="tooltip-top"
						title="View Employment Details"
						placement="bottom"
						classes={{ tooltip: classes.tooltip }}
					>
						<IconButton
							aria-label="Download"
							className={classes.documenticonbutton}
							onClick={async () => {
								self.setState({
									loadingalert: true,
									loadingalert_error: false
								});
								await client
									.query({
										query: SingleEmployeeEmploymentQuery,
										variables: {
											username: localStorage.getItem("username"),
											employee_username: all_employees[key].username
										}
									})
									.then(response => {
										if (response.data.getSingleUserEmployment === null) {
											self.setState({
												loadingalert: false,
												profile_status: all_employees[key].profile_status,
												// employee_data: all_employees[key],
												show_employee_document_list: true,

												show_allemployee_list: false,
												show_employee_revoke: false,
												show_employee_unblock: false,
												show_employee_profile: false,
												employee_employment_data: null
												//set the particular employee data in state variable employee employment data
											});
										} else {
											self.setState({
												loadingalert: false,
												// employee_data: all_employees[key],
												show_employee_document_list: true,
												show_allemployee_list: false,
												show_employee_revoke: false,
												show_employee_profile: false,
												show_employee_unblock: false,
												employee_employment_data:
													response.data.getSingleUserEmployment //set the particular employee data in state variable employee employment data
											});
										}
									})
									.catch(err => {
										self.setState({
											loadingalert: false,
											loadingalert_error: true
										});
									});
							}}
						>
							<Icon>
								<i className="fa fa-folder-open" />
							</Icon>
						</IconButton>
					</Tooltip>
					&nbsp; &nbsp;
					{"  "}
					{/* Icon button to edit the employee exit process for current employee*/}
					{all_employees[key].profile_status === "Blocked" ? (
						<Tooltip
							id="tooltip-top"
							title="Unblock User"
							placement="bottom"
							classes={{ tooltip: classes.tooltip }}
						>
							<IconButton
								aria-label="Download"
								className={classes.blockiconbutton}
								onClick={async () => {
									self.setState({
										loadingalert: true,
										loadingalert_error: false
									});
									await client
										.query({
											query: EmployeeProfileQuery,
											variables: {
												username: localStorage.getItem("username"),
												employee_username: all_employees[key].username
											}
										})
										.then(response => {
											if (response.data.getSingleEmployee.profile_image_hash) {
												try {
													//retrieve Employee profile image from s3
													const retrieveProfileData = {
														userName: response.data.getSingleEmployee.username,
														hasedFilename:
															response.data.getSingleEmployee.profile_image_hash
													};
													axios
														.post(
															config.profileImage.EmployeeProfileRetrieve,
															retrieveProfileData
														)
														.then(profileDatabase64 => {
															if (profileDatabase64.data.data) {
																self.setState({
																	loadingalert: false,
																	employee_data: all_employees[key],
																	employeee_data_profile:
																		response.data.getSingleEmployee, //set the particular employee data in state variable employee employment data
																	employee_fileContent:
																		"data:image/jpeg;base64," +
																		profileDatabase64.data.data.fileContent,
																	employee_data_profile: all_employees[key], //set the particular employee data in state variable employee employment data
																	show_employee_document_list: false,
																	show_allemployee_list: true,
																	show_employee_revoke: false,
																	show_employee_unblock: true
																});
															} else {
																self.setState({
																	loadingalert: false,
																	employee_data: all_employees[key],
																	show_employee_profile: false,
																	show_allemployee_list: false,
																	show_employee_view: false,
																	show_employee_revoke: false,
																	show_employee_unblock: true,
																	employee_data_profile:
																		response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
																});
															}
														})
														.catch(err => {
															self.setState({
																loadingalert: false,
																employee_data: all_employees[key],
																show_employee_profile: false,
																show_allemployee_list: false,
																show_employee_view: false,
																show_employee_revoke: false,
																show_employee_unblock: true,
																employee_data_profile:
																	response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
															});
														});
												} catch (e) {
													self.setState({
														loadingalert: false,
														employee_data: all_employees[key],
														show_employee_profile: false,
														show_allemployee_list: false,
														show_employee_view: false,
														show_employee_revoke: false,
														show_employee_unblock: true,
														employee_data_profile:
															response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
													});
												}
											} else {
												self.setState({
													loadingalert: false,
													employee_data: all_employees[key],
													show_employee_profile: false,
													show_allemployee_list: true,
													show_employee_view: false,
													show_employee_revoke: false,
													show_employee_unblock: true,
													employee_data_profile: response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
												});
											}
										})
										.catch(err => {
											self.setState({
												loadingalert: false,
												loadingalert_error: true
											});
										});
								}}
							>
								<Icon>lock_open</Icon>
							</IconButton>

							{/* <IconButton
								aria-label='Download'
								className={classes.blockiconbutton}
								onClick={() => {
									self.setState({
										employee_data: all_employees[key], //set the particular employee data in state variable employee employment data
										show_employee_document_list: false,
										show_allemployee_list: true,
										show_employee_revoke: false,
										show_employee_unblock: true
									});
								}}
							>
								<Icon>lock_open</Icon>
							</IconButton> */}
						</Tooltip>
					) : (
						<Tooltip
							id="tooltip-top"
							title="Block User"
							placement="bottom"
							classes={{ tooltip: classes.tooltip }}
						>
							<IconButton
								aria-label="Download"
								className={classes.blockiconbutton}
								onClick={async () => {
									self.setState({
										loadingalert: true,
										loadingalert_error: false
									});
									await client
										.query({
											query: EmployeeProfileQuery,
											variables: {
												username: localStorage.getItem("username"),
												employee_username: all_employees[key].username
											}
										})
										.then(response => {
											if (response.data.getSingleEmployee.profile_image_hash) {
												try {
													//retrieve Employee profile image from s3
													const retrieveProfileData = {
														userName: response.data.getSingleEmployee.username,
														hasedFilename:
															response.data.getSingleEmployee.profile_image_hash
													};
													axios
														.post(
															config.profileImage.EmployeeProfileRetrieve,
															retrieveProfileData
														)
														.then(profileDatabase64 => {
															if (profileDatabase64.data.data) {
																self.setState({
																	loadingalert: false,
																	employee_data: all_employees[key],
																	employeee_data_profile:
																		response.data.getSingleEmployee, //set the particular employee data in state variable employee employment data
																	employee_fileContent:
																		"data:image/jpeg;base64," +
																		profileDatabase64.data.data.fileContent,
																	employee_data_profile: all_employees[key], //set the particular employee data in state variable employee employment data
																	show_employee_document_list: false,
																	show_allemployee_list: true,
																	show_employee_revoke: true,
																	show_employee_unblock: false
																});
															} else {
																self.setState({
																	loadingalert: false,
																	employee_data: all_employees[key],
																	show_employee_profile: false,
																	show_allemployee_list: true,
																	show_employee_view: false,
																	show_employee_revoke: true,
																	show_employee_unblock: false,
																	employee_data_profile:
																		response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
																});
															}
														})
														.catch(err => {
															self.setState({
																loadingalert: false,
																employee_data: all_employees[key],
																show_employee_profile: false,
																show_allemployee_list: true,
																show_employee_view: false,
																show_employee_revoke: true,
																show_employee_unblock: false,
																employee_data_profile:
																	response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
															});
														});
												} catch (e) {
													self.setState({
														loadingalert: false,
														employee_data: all_employees[key],
														show_employee_profile: false,
														show_allemployee_list: true,
														show_employee_view: false,
														show_employee_revoke: true,
														show_employee_unblock: false,
														employee_data_profile:
															response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
													});
												}
											} else {
												self.setState({
													loadingalert: false,
													employee_data: all_employees[key],
													show_employee_profile: false,
													show_allemployee_list: true,
													show_employee_view: false,
													show_employee_revoke: true,
													show_employee_unblock: false,
													employee_data_profile: response.data.getSingleEmployee //set the particular employee data in state variable employee employment data
												});
											}
										})
										.catch(err => {
											self.setState({
												loadingalert: false,
												loadingalert_error: true
											});
										});
								}}
							>
								<Icon>block</Icon>
							</IconButton>

							{/* <IconButton
								aria-label="Download"
								className={classes.blockiconbutton}
								onClick={() => {
									self.setState({
										employee_data: all_employees[key], //set the particular employee data in state variable employee employment data
										show_employee_document_list: false,
										show_allemployee_list: true,
										show_employee_revoke: true,
										show_employee_unblock: false
									});
								}}
							>
								<Icon>block</Icon>
							</IconButton> */}
						</Tooltip>
					)}
				</div>
			]);
		});

		self.setState({
			employee_tabledata: employee_tabledata
		});
	}
	render() {
		const { classes, client } = this.props;
		const options = {
			filterType: "dropdown",
			responsive: "stacked",
			fixedHeader: true,
			page: this.state.page,
			selectableRows: false
		};
		const columns = [
			{
				name: "Username", // TODO: Now there is no uniqueid so Username is used as Uniqueid
				options: {
					filter: true,
					sort: true,
					display: false
				}
			},
			{
				name: "Employee Name",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Organization Name",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Email",
				options: {
					filter: true,
					sort: true,
					display: false
				}
			},
			{
				name: "Phone Number",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Onboarded On",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Status",
				options: {
					display: false,
					filterList:
						this.state.employee_status === undefined
							? ["Active", "Blocked"]
							: this.state.employee_status
				}
			},
			{
				name: "Action",
				options: {
					filter: false,
					sort: false,
					download: false
				}
			}
		];
		//when error occurs
		if (this.state.hasError) {
			return (
				<div>
					<ErrorPage />{" "}
				</div>
			);
		} else {
			return (
				<React.Fragment>
					<GridContainer>
						<GridItem xs={12} sm={12} lg={12}>
							<Snackbar
								place="bc"
								color="danger"
								icon={AddAlert}
								message={
									this.state.failalert ? <b>{this.state.message}</b> : ""
								}
								open={this.state.failalert}
								closeNotification={() => this.setState({ failalert: false })}
								close
							/>
							<Snackbar
								place="bc"
								color="info"
								icon={AddAlert}
								message={
									this.state.successalert ? <b>{this.state.message}</b> : ""
								}
								open={this.state.successalert}
								closeNotification={() => this.setState({ successalert: false })}
								close
							/>
							{this.state.show_allemployee_list ? (
								<Card>
									<CardHeader color="info" icon>
										<CardIcon color="info">
											<Icon>
												<i className="fa fa-users" />
											</Icon>
										</CardIcon>
										<h4 className={classes.cardIconTitle}>
											All Employees List
										</h4>
									</CardHeader>
									<CardBody>
										<MuiThemeProvider theme={Mytheme}>
											<MUIDataTable
												title={""}
												data={this.state.employee_tabledata}
												columns={columns}
												options={options}
											/>
										</MuiThemeProvider>
									</CardBody>
								</Card>
							) : null}
							{this.state.show_employee_document_list ? (
								this.state.employee_employment_data === null ? (
									<DataNotFound
										message={"No Document Uploaded by the User Yet."}
										handleBack={this.handleBack}
										backbutton={true}
									/>
								) : (
									// Employee  Employment View Page
									<Suspense fallback={<ScreenLoading />}>
										<EmployeeDocumentList
											handleBack={this.handleBack}
											profile_status={this.state.profile_status}
											employee_employment_data={
												this.state.employee_employment_data
											}
											client={client}
										/>
									</Suspense>
								)
							) : null}
							{this.state.show_employee_profile ? (
								<Suspense fallback={<ScreenLoading />}>
									<EmployeeProfile
										employee_profile_data={this.state.employee_profile_data}
										employee_data={this.state.employee_data}
										employee_fileContent={this.state.employee_fileContent}
										handleback={this.handleBack}
									/>
								</Suspense>
							) : null}
							{this.state.show_employee_revoke ? (
								<Suspense fallback={<ScreenLoading />}>
									<EmployeeRevoke
										employee_data={this.state.employee_data}
										employee_data_profile={this.state.employee_data_profile}
										handleback={(status, message) =>
											this.handlebackRevoke(status, message)
										}
									/>
								</Suspense>
							) : null}
							{this.state.show_employee_unblock ? (
								<EmployeeUnblock
									blocked_employee_data={this.state.employee_data}
									employee_data_profile={this.state.employee_data_profile}
									handleback={(status, message) =>
										this.handlebackRevoke(status, message)
									}
								/>
							) : null}
						</GridItem>
					</GridContainer>
					{this.state.loadingalert ? <ScreenLoading /> : null}
					{this.state.loadingalert_error ? (
						<SweetAlert
							style={{
								display: "block",
								marginTop: "-100px",
								color: "#000000"
							}}
							title="Failed to fetch."
							onConfirm={() => this.setState({ loadingalert_error: false })}
							showConfirm={true}
						>
							<h4>Please try Again.</h4>
						</SweetAlert>
					) : null}
				</React.Fragment>
			);
		}
	}
}

export default withStyles(styles)(Myemployee);
