import gql from "graphql-tag";
const SingleEmployeeEmploymentQuery = gql`
  query EmploymentQuery($username: String!, $employee_username: String) {
    getSingleUserEmployment(
      username: $username
      employee_username: $employee_username
    ) {
      employment {
        username
        document_name
        file_id
        file_hash
        is_verified_employer
        document_extension
        document_upload_type
        employee_id
        employer_name
        employer_mail_id
        email_hash
        organization_name
        joining_date
        end_date
        experience
        designation
        department
        uploaded_by
        uploaded_at
        modified_at
        verified_status
        approved_by
        approver_email
        __typename
      }
      __typename
    }
  }
`;
export default SingleEmployeeEmploymentQuery;
