import LoginPage from "Container/Auth/LoginPage.jsx";
import ForgetPasswordPage from "Container/Auth/Forget password.jsx";
// import LockScreen from "Container/Auth/LockScreen.jsx"; //! future

// @material-ui/icons
import Fingerprint from "@material-ui/icons/Fingerprint";
import LockOpen from "@material-ui/icons/LockOpen";

const unauthenticatedroutes = [
	{
		path: "/auth/login",
		name: "Login Page",
		short: "Login",
		mini: "LP",
		icon: Fingerprint,
		component: LoginPage
	},
	//! future
	// {
	// 	path: "/auth/lockscreen",
	// 	name: "LockScreen",
	// 	short: "Lock",
	// 	mini: "LS",
	// 	icon: Fingerprint,
	// 	component: LockScreen
	// },
	{
		path: "/auth/ForgetPasswordPage",
		name: "Forget Password Page",
		short: "Forget Password",
		mini: "FP",
		icon: LockOpen,
		component: ForgetPasswordPage
	},
	{
		redirect: true,
		path: "/auth",
		pathTo: "/auth/login",
		name: "Login Page"
	}
];

export default unauthenticatedroutes;
