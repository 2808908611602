import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
//core components

//queries
import Notificationquery from "../../Graphql/Querries/Notificationquery";
import HeaderLinks from "./HeaderLinks.jsx";

var isError = false;
// retrieve all notifications
const NotificationQuery = ({
	username = localStorage.getItem("username"),
	client,
	history,
	rtlActive,
	back
}) => (
	<Query query={Notificationquery} variables={{ username }}>
		{({ loading, data, error }) => {
			if (loading) {
				return true;
			}

			if (error) {
				isError = true;
			}
			if (back && !isError) {
				back(data.getNotification);
			}
			return (
				<div>
					{isError ? (
						<HeaderLinks
							getNotification={{ notifications: null }}
							rtlActive={rtlActive}
							history={history}
							client={client}
							isError={isError}
						/>
					) : (
						<HeaderLinks
							getNotification={data.getNotification}
							rtlActive={rtlActive}
							history={history}
							client={client}
						/>
					)}
				</div>
			);
		}}
	</Query>
);

class GetNotification extends React.Component {
	render() {
		return (
			<ApolloConsumer>
				{client => (
					<NotificationQuery
						history={this.props.history}
						client={client}
						rtlActive={this.props.rtlActive}
						back={this.props.back}
					/>
				)}
			</ApolloConsumer>
		);
	}
}

export default GetNotification;
