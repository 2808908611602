// ##############################
//   authenticatedlayoutstyle
// #############################

import { drawerWidth, drawerMiniWidth, transition, containerFluid } from 'assets/jss/material-dashboard-pro-react.jsx';
import { defaultFont } from 'assets/jss/material-dashboard-pro-react.jsx';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.jsx';
const authenticatedlayoutstyle = theme => ({
	wrapper: {
		position: 'relative',
		top: '0',
		height: '100vh',
		'&:after': {
			display: 'table',
			clear: 'both',
			content: '" "',
		},
	},
	mainPanel: {
		transitionProperty: 'top, bottom, width',
		transitionDuration: '.2s, .2s, .35s',
		transitionTimingFunction: 'linear, linear, ease',
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${drawerWidth}px)`,
		},
		overflow: 'auto',
		position: 'relative',
		float: 'right',
		...transition,
		maxHeight: '100%',
		width: '100%',
		overflowScrolling: 'touch',
	},
	content: {
		marginTop: '70px',
		padding: '30px 15px',
		minHeight: 'calc(100vh - 123px)',
	},
	container: { ...containerFluid },
	map: {
		marginTop: '70px',
	},
	mainPanelSidebarMini: {
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${drawerMiniWidth}px)`,
		},
	},
	mainPanelWithPerfectScrollbar: {
		overflow: 'hidden !important',
	},
	cardTitle: {
		marginTop: '0',
		marginBottom: '3px',
		color: '#3C4858',
		fontSize: '18px',
	},
	cardHeader: {
		zIndex: '3',
	},
	cardSubtitle: {
		...defaultFont,
		color: '#999999',
		fontSize: '14px',
		margin: '0 0 10px',
	},
	center: {
		textAlign: 'center',
	},
	right: {
		textAlign: 'right',
	},
	left: {
		textAlign: 'left',
	},
	marginRight: {
		marginRight: '5px',
	},
	modalSectionTitle: {
		marginTop: '30px',
	},
	...modalStyle(theme),
});

export default authenticatedlayoutstyle;
