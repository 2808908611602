import React, { Suspense } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import MUIDataTable from "mui-datatables";
import axios from "axios";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Domain from "@material-ui/icons/Domain";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// Import files
import EmployerUnblock from "../BlockedUsers/Authority/AuthorityUnblock";
import DataNotFound from "../DataNotFound";
import config from "../../config";
import ScreenLoading from "../LoadingScreen.jsx";

// Styles
import {
	cardTitle,
	warningColor,
	dangerColor,
	infoColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import Mytheme from "../CustomStyles/customMuithemeTable";

// Queries and mutation
import EmployerProfileQuery from "../../Graphql/Querries/EmployerProfileQuery";
import DocumentRequestedQuery from "../../Graphql/Querries/DocumentRequestedQuery";
// Date formater
var dateFormat = require("dateformat");
const EmployerProfile = React.lazy(() => import("./EmployerProfile.jsx"));
const EmployerRevoke = React.lazy(() => import("./EmployerRevoke.jsx"));
const RequestedDocumentList = React.lazy(() =>
	import("./RequestedDocumentList.jsx")
);

const Custom_styles = {
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px"
	},
	profileiconbutton: {
		color: infoColor,
		padding: "0px",
		backgroundColor: "#ffffff00",
		"&:hover": {
			backgroundColor: "#eeeeee"
		}

		// backgroundColor:'#33DDFF',
	},
	requesteddocumenticonbutton: {
		color: warningColor,
		padding: "0px",
		backgroundColor: "#ffffff00",
		"&:hover": {
			backgroundColor: "#eeeeee"
		}
	},
	blockiconbutton: {
		color: dangerColor,
		padding: "0px",
		backgroundColor: "#ffffff00",
		"&:hover": {
			backgroundColor: "#eeeeee"
		}
		// backgroundColor:'#33DDFF',
	}
};

class AllEmployerList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingalert: false,
			loadingalert_error: false,
			successalert: false,
			failalert: false,
			message: "",
			employer_profile_data: "",
			employee_tabledata: [],
			employer_data: [],
			employer_document_request: "",

			//variable to handle hide/show of pages
			show_company_details: false,
			show_allemployee_list: true,
			show_company_profile: false,
			show_Employer_block: false,
			show_Employer_Unblock: false,
			profile_status: "Active",
			employee_count: "",
			employer_doc_verified_count: "",
			employer_fileContent: null,
			employer_status: !this.props.status
				? ["Active", "Blocked"]
				: [this.props.status]
		};
	}

	handleBack = () => {
		this.setState({
			show_allemployee_list: true,
			show_company_details: false,
			show_company_profile: false,
			show_Employer_block: false,
			show_Employer_Unblock: false
		});
	};
	componentDidCatch(error, info) {
		if (
			error.toString().includes("greater than the total available page length")
		) {
			this.setState({ page: 0 });
		} else {
			this.setState({ hashError: true });
		}
	}
	handleEmployeeProfile = () => {
		this.setState({
			show_allemployee_list: false,
			show_employee_view: false,
			show_company_profile: true
		});
	};
	handlebackRevoke = (status, message) => {
		if (status === "Success") {
			this.setState({
				show_Employer_block: false,
				show_Employer_Unblock: false,
				successalert: true,
				message: message
			});
		} else if (status === "Failed") {
			this.setState({
				show_Employer_block: false,
				show_Employer_Unblock: false,
				failalert: true,
				message: message
			});
		} else {
			this.setState({
				show_Employer_block: false,
				show_Employer_Unblock: false
			});
		}
	};
	componentDidMount() {
		var employee_tabledata = [];
		var self = this;

		const { classes, AllEmployersList, client } = this.props;
		var all_employers_list = AllEmployersList.employer;

		//* Employer List view data *//
		//for grid view element should be in same order, column and the data pushed here should be same column count.
		Object.keys(all_employers_list).forEach(function(key) {
			employee_tabledata.push([
				all_employers_list[key].username,

				all_employers_list[key].name,
				all_employers_list[key].company_name,
				all_employers_list[key].email,
				all_employers_list[key].phone_number,
				dateFormat(new Date(all_employers_list[key].created_at), "dd/mm/yyyy"),
				all_employers_list[key].profile_status,
				<div className="actions-right">
					{/* Icon button to view the employment data in details */}
					<Tooltip
						id="tooltip-top"
						title="View Company Profile"
						placement="bottom"
						classes={{ tooltip: classes.tooltip }}
					>
						<IconButton
							aria-label="Download"
							className={classes.profileiconbutton}
							onClick={async () => {
								self.setState({
									loadingalert: true,
									show_allemployee_list: false,
									loadingalert_error: false
								});
								await client
									.query({
										query: EmployerProfileQuery,
										variables: {
											username: localStorage.getItem("username"),
											employer_username: all_employers_list[key].username
										}
									})
									.then(response => {
										if (response.data.getSingleEmployer.profile_image_hash) {
											try {
												//retrieving Employer profile from s3
												const retrieveProfileDataEmployer = {
													userName: response.data.getSingleEmployer.username,
													hasedFilename:
														response.data.getSingleEmployer.profile_image_hash
												};
												axios
													.post(
														config.profileImage.EmployerProfileRetrieve,
														retrieveProfileDataEmployer
													)
													.then(profileDataEmployer => {
														if (profileDataEmployer.data.data) {
															self.setState({
																loadingalert: false,
																profile_status:
																	all_employers_list[key].profile_status,
																//employee data in state variable employee employment data
																show_company_profile: true,
																show_allemployee_list: false,
																show_employee_view: false,
																employer_profile_data:
																	response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
																employee_count:
																	response.data.employeesCountUnderEmployer,
																employer_doc_verified_count:
																	response.data.documentCountApprovedByEmployer,
																employer_fileContent:
																	"data:image/jpeg;base64," +
																	profileDataEmployer.data.data.fileContent
															});
														} else {
															self.setState({
																loadingalert: false,
																//employee data in state variable employee employment data
																show_company_profile: true,
																show_allemployee_list: false,
																show_employee_view: false,
																employer_profile_data:
																	response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
																employee_count:
																	response.data.employeesCountUnderEmployer,
																employer_doc_verified_count:
																	response.data.documentCountApprovedByEmployer
															});
														}
													})
													.catch(err => {
														self.setState({
															loadingalert: false,
															//employee data in state variable employee employment data
															show_company_profile: true,
															show_allemployee_list: false,
															show_employee_view: false,
															employer_profile_data:
																response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
															employee_count:
																response.data.employeesCountUnderEmployer,
															employer_doc_verified_count:
																response.data.documentCountApprovedByEmployer
														});
													});
											} catch (e) {
												self.setState({
													loadingalert: false,
													//employee data in state variable employee employment data
													show_company_profile: true,
													show_allemployee_list: false,
													show_employee_view: false,
													employer_profile_data:
														response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
													employee_count:
														response.data.employeesCountUnderEmployer,
													employer_doc_verified_count:
														response.data.documentCountApprovedByEmployer
												});
											}
										} else {
											self.setState({
												loadingalert: false,
												//employee data in state variable employee employment data
												show_company_profile: true,
												show_allemployee_list: false,
												show_employee_view: false,
												employer_profile_data: response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
												employee_count:
													response.data.employeesCountUnderEmployer,
												employer_doc_verified_count:
													response.data.documentCountApprovedByEmployer
											});
										}
									})
									.catch(err => {
										self.setState({
											loadingalert: false,
											loadingalert_error: true,
											show_allemployee_list: true
										});
									});
							}}
						>
							<Icon>account_circle</Icon>
						</IconButton>
					</Tooltip>{" "}
					&nbsp; &nbsp;
					{/* Icon button to edit the employee exit process for current employee*/}
					<Tooltip
						id="tooltip-top"
						title="View Documents"
						placement="bottom"
						classes={{ tooltip: classes.tooltip }}
					>
						<IconButton
							aria-label="Download"
							className={classes.requesteddocumenticonbutton}
							onClick={async () => {
								self.setState({
									loadingalert: true,
									loadingalert_error: false,
									show_allemployee_list: false
								});
								await client
									.query({
										query: DocumentRequestedQuery,
										variables: {
											username: localStorage.getItem("username"),
											employer_username: all_employers_list[key].username
										}
									})
									.then(response => {
										self.setState({
											loadingalert: false,

											employer_data: all_employers_list[key], //set the particular employee data in state variable employee employment data
											show_company_details: true,
											show_allemployee_list: false,

											show_company_profile: false,

											employer_document_request:
												response.data.documentRequest === null
													? null
													: response.data.documentRequest.employment //set the particular employee data in state variable employee employment data
										});
									})
									.catch(err => {
										self.setState({
											loadingalert: false,
											show_allemployee_list: true,
											loadingalert_error: true
										});
									});
							}}
						>
							<Icon>assignment</Icon>
						</IconButton>
					</Tooltip>{" "}
					&nbsp; &nbsp;
					{/* Icon button to edit the employee exit process for current employee*/}
					{all_employers_list[key].profile_status === "Blocked" ? (
						<Tooltip
							id="tooltip-top"
							title="Unblock User"
							placement="bottom"
							classes={{ tooltip: classes.tooltip }}
						>
							{/* <IconButton
								aria-label="Download"
								className={classes.blockiconbutton}
								onClick={() => {
									self.setState({
										employer_data: all_employers_list[key], //set the particular employee data in state variable employee employment data
										show_company_details: false,
										show_allemployee_list: true,
										show_Employer_block: false,
										show_Employer_Unblock: true
									});
								}}
							>
								<Icon>lock_open</Icon>
							</IconButton> */}

							<IconButton
								aria-label="Download"
								className={classes.blockiconbutton}
								onClick={async () => {
									self.setState({
										loadingalert: true,
										loadingalert_error: false,
										show_allemployee_list: false
									});
									await client
										.query({
											query: EmployerProfileQuery,
											variables: {
												username: localStorage.getItem("username"),
												employer_username: all_employers_list[key].username
											}
										})
										.then(response => {
											if (response.data.getSingleEmployer.profile_image_hash) {
												try {
													//retrieving Employer profile from s3
													const retrieveProfileDataEmployer = {
														userName: response.data.getSingleEmployer.username,
														hasedFilename:
															response.data.getSingleEmployer.profile_image_hash
													};
													axios
														.post(
															config.profileImage.EmployerProfileRetrieve,
															retrieveProfileDataEmployer
														)
														.then(profileDataEmployer => {
															if (profileDataEmployer.data.data) {
																self.setState({
																	loadingalert: false,
																	profile_status:
																		all_employers_list[key].profile_status,
																	//employee data in state variable employee employment data
																	employer_data: all_employers_list[key], //set the particular employee data in state variable employee employment data
																	show_company_details: false,
																	show_allemployee_list: true,
																	show_Employer_block: false,
																	show_Employer_Unblock: true,

																	employer_profile_data:
																		response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
																	employee_count:
																		response.data.employeesCountUnderEmployer,
																	employer_doc_verified_count:
																		response.data
																			.documentCountApprovedByEmployer,
																	employer_fileContent:
																		"data:image/jpeg;base64," +
																		profileDataEmployer.data.data.fileContent
																});
															} else {
																self.setState({
																	loadingalert: false,
																	employer_data: all_employers_list[key], //set the particular employee data in state variable employee employment data
																	show_company_details: false,
																	show_allemployee_list: true,
																	show_Employer_block: false,
																	show_Employer_Unblock: true,
																	employer_profile_data:
																		response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
																	employee_count:
																		response.data.employeesCountUnderEmployer,
																	employer_doc_verified_count:
																		response.data
																			.documentCountApprovedByEmployer
																});
															}
														})
														.catch(err => {
															self.setState({
																loadingalert: false,
																employer_data: all_employers_list[key], //set the particular employee data in state variable employee employment data
																show_company_details: false,
																show_allemployee_list: true,
																show_Employer_block: false,
																show_Employer_Unblock: true,
																employer_profile_data:
																	response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
																employee_count:
																	response.data.employeesCountUnderEmployer,
																employer_doc_verified_count:
																	response.data.documentCountApprovedByEmployer
															});
														});
												} catch (e) {
													self.setState({
														loadingalert: false,
														employer_data: all_employers_list[key], //set the particular employee data in state variable employee employment data
														show_company_details: false,
														show_allemployee_list: true,
														show_Employer_block: false,
														show_Employer_Unblock: true,
														employer_profile_data:
															response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
														employee_count:
															response.data.employeesCountUnderEmployer,
														employer_doc_verified_count:
															response.data.documentCountApprovedByEmployer
													});
												}
											} else {
												self.setState({
													loadingalert: false,
													employer_data: all_employers_list[key], //set the particular employee data in state variable employee employment data
													show_company_details: false,
													show_allemployee_list: true,
													show_Employer_block: false,
													show_Employer_Unblock: true,
													employer_profile_data:
														response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
													employee_count:
														response.data.employeesCountUnderEmployer,
													employer_doc_verified_count:
														response.data.documentCountApprovedByEmployer
												});
											}
										})
										.catch(err => {
											self.setState({
												loadingalert: false,
												loadingalert_error: true,
												show_allemployee_list: true
											});
										});
								}}
							>
								<Icon>lock_open</Icon>
							</IconButton>
						</Tooltip>
					) : (
						<Tooltip
							id="tooltip-top"
							title="Block User"
							placement="bottom"
							classes={{ tooltip: classes.tooltip }}
						>
							<IconButton
								aria-label="Download"
								className={classes.blockiconbutton}
								onClick={async () => {
									self.setState({
										loadingalert: true,
										loadingalert_error: false,
										show_allemployee_list: false
									});
									await client
										.query({
											query: EmployerProfileQuery,
											variables: {
												username: localStorage.getItem("username"),
												employer_username: all_employers_list[key].username
											}
										})
										.then(response => {
											if (response.data.getSingleEmployer.profile_image_hash) {
												try {
													//retrieving Employer profile from s3
													const retrieveProfileDataEmployer = {
														userName: response.data.getSingleEmployer.username,
														hasedFilename:
															response.data.getSingleEmployer.profile_image_hash
													};
													axios
														.post(
															config.profileImage.EmployerProfileRetrieve,
															retrieveProfileDataEmployer
														)
														.then(profileDataEmployer => {
															if (profileDataEmployer.data.data) {
																self.setState({
																	employer_data: all_employers_list[key],
																	loadingalert: false,
																	profile_status:
																		all_employers_list[key].profile_status,
																	//employee data in state variable employee employment data
																	// show_company_profile: true,
																	show_employee_view: false,
																	show_company_details: false,
																	show_allemployee_list: true,
																	show_Employer_block: true,
																	show_Employer_Unblock: false,

																	employer_profile_data:
																		response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
																	employee_count:
																		response.data.employeesCountUnderEmployer,
																	employer_doc_verified_count:
																		response.data
																			.documentCountApprovedByEmployer,
																	employer_fileContent:
																		"data:image/jpeg;base64," +
																		profileDataEmployer.data.data.fileContent
																});
															} else {
																self.setState({
																	loadingalert: false,
																	employer_data: all_employers_list[key],
																	//employee data in state variable employee employment data
																	show_company_details: false,
																	show_allemployee_list: true,
																	show_Employer_block: true,
																	show_Employer_Unblock: false,
																	employer_profile_data:
																		response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
																	employee_count:
																		response.data.employeesCountUnderEmployer,
																	employer_doc_verified_count:
																		response.data
																			.documentCountApprovedByEmployer
																});
															}
														})
														.catch(err => {
															self.setState({
																loadingalert: false,
																employer_data: all_employers_list[key],
																//employee data in state variable employee employment data
																show_company_details: false,
																show_allemployee_list: true,
																show_Employer_block: true,
																show_Employer_Unblock: false,
																employer_profile_data:
																	response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
																employee_count:
																	response.data.employeesCountUnderEmployer,
																employer_doc_verified_count:
																	response.data.documentCountApprovedByEmployer
															});
														});
												} catch (e) {
													self.setState({
														loadingalert: false,
														employer_data: all_employers_list[key],
														//employee data in state variable employee employment data
														show_company_details: false,
														show_allemployee_list: true,
														show_Employer_block: true,
														show_Employer_Unblock: false,
														employer_profile_data:
															response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
														employee_count:
															response.data.employeesCountUnderEmployer,
														employer_doc_verified_count:
															response.data.documentCountApprovedByEmployer
													});
												}
											} else {
												self.setState({
													loadingalert: false,
													employer_data: all_employers_list[key],
													//employee data in state variable employee employment data
													show_company_details: false,
													show_allemployee_list: true,
													show_Employer_block: true,
													show_Employer_Unblock: false,
													employer_profile_data:
														response.data.getSingleEmployer, //set the particular employee data in state variable employee employment data
													employee_count:
														response.data.employeesCountUnderEmployer,
													employer_doc_verified_count:
														response.data.documentCountApprovedByEmployer
												});
											}
										})
										.catch(err => {
											self.setState({
												loadingalert: false,
												loadingalert_error: true,
												show_allemployee_list: true
											});
										});
								}}
							>
								<Icon>block</Icon>
							</IconButton>

							{/* <IconButton
								aria-label="Download"
								className={classes.blockiconbutton}
								onClick={() => {
									self.setState({
										employer_data: all_employers_list[key], //set the particular employee data in state variable employee employment data
										show_company_details: false,
										show_allemployee_list: true,
										show_Employer_block: true,
										show_Employer_Unblock: false
									});
								}}
							>
								<Icon>block</Icon>
							</IconButton> */}
						</Tooltip>
					)}
				</div>
			]);
		});

		self.setState({
			employee_tabledata: employee_tabledata
		});
	}
	render() {
		const { classes, client } = this.props;
		const options = {
			filterType: "dropdown",
			responsive: "stacked",
			fixedHeader: true,
			page: this.state.page,
			selectableRows: false
		};
		const columns = [
			{
				name: "Username",
				options: {
					filter: true,
					sort: true,
					display: false
				}
			},
			{
				name: "Authority Name",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Organization Name",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Email",
				options: {
					filter: true,
					sort: true,
					display: false
				}
			},
			{
				name: "Phone Number",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Onboarded On",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Status",
				options: {
					display: false,
					filterList:
						this.state.employer_status === undefined
							? ["Active", "Blocked"]
							: this.state.employer_status
				}
			},
			{
				name: "Action",
				options: {
					filter: false,
					sort: false,
					download: false
				}
			}
		];
		return (
			<React.Fragment>
				<GridContainer>
					<GridItem xs={12} sm={12} lg={12}>
						<Snackbar
							place="bc"
							color="danger"
							icon={AddAlert}
							message={this.state.failalert ? <b>{this.state.message}</b> : ""}
							open={this.state.failalert}
							closeNotification={() => this.setState({ failalert: false })}
							close
						/>
						<Snackbar
							place="bc"
							color="info"
							icon={AddAlert}
							message={
								this.state.successalert ? <b>{this.state.message}</b> : ""
							}
							open={this.state.successalert}
							closeNotification={() => this.setState({ successalert: false })}
							close
						/>
						{this.state.show_allemployee_list ? (
							<Card>
								<CardHeader color="info" icon>
									<CardIcon color="info">
										<Domain />
									</CardIcon>
									<h4 className={classes.cardIconTitle}>
										All Authorities List
									</h4>
								</CardHeader>
								<CardBody>
									<MuiThemeProvider theme={Mytheme}>
										<MUIDataTable
											title={""}
											data={this.state.employee_tabledata}
											columns={columns}
											options={options}
										/>
									</MuiThemeProvider>
								</CardBody>
							</Card>
						) : null}
						{this.state.show_company_details ? (
							this.state.employer_document_request === null ? (
								<DataNotFound
									message={"There Is No Request For This Particular Authority"}
									handleBack={this.handleBack}
									backbutton={true}
								/>
							) : (
								// Employee  Employment View Page
								<Suspense fallback={<ScreenLoading />}>
									<RequestedDocumentList
										handleBack={this.handleBack}
										profile_status={this.state.profile_status}
										employer_document_request={
											this.state.employer_document_request
										}
										client={client}
									/>
								</Suspense>
							)
						) : null}

						{this.state.show_company_profile ? (
							<Suspense fallback={<ScreenLoading />}>
								<EmployerProfile
									employer_profile_data={this.state.employer_profile_data}
									employee_count={this.state.employee_count}
									employer_fileContent={this.state.employer_fileContent}
									employer_doc_verified_count={
										this.state.employer_doc_verified_count
									}
									handleback={this.handleBack}
								/>
							</Suspense>
						) : null}
						{this.state.show_Employer_block ? (
							<Suspense fallback={<ScreenLoading />}>
								<EmployerRevoke
									employer_data={this.state.employer_data}
									handleback={(status, message) =>
										this.handlebackRevoke(status, message)
									}
								/>
							</Suspense>
						) : null}
						{this.state.show_Employer_Unblock ? (
							<EmployerUnblock
								blocked_employers_data={this.state.employer_data}
								handleback={(status, message) =>
									this.handlebackRevoke(status, message)
								}
							/>
						) : null}
					</GridItem>
				</GridContainer>
				{this.state.loadingalert ? <ScreenLoading /> : null}
				{this.state.loadingalert_error ? (
					<SweetAlert
						style={{
							display: "block",
							marginTop: "-100px",
							color: "#000000"
						}}
						title="Failed to fetch."
						onConfirm={() => this.setState({ loadingalert_error: false })}
						showConfirm={true}
					>
						<h4>Please try Again.</h4>
					</SweetAlert>
				) : null}
			</React.Fragment>
		);
	}
}

export default withStyles(Custom_styles)(AllEmployerList);
