import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
import ScreenLoading from "../LoadingScreen.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// Queries and mutation
import UnverifiedEmployerListQuery from "../../Graphql/Querries/UnverifiedEmployerListQuery";
// file imports
import Errorpage from "../Pages/errorPage.jsx";
import DataNotFound from "../DataNotFound";
import UnverifiedEmployerList from "./UnverifiedEmployerList.jsx";

//Employment query
const UnverifiedEmployerquery = ({
	username = localStorage.getItem("username"),
	history,
	client
}) => (
	<Query query={UnverifiedEmployerListQuery} variables={{ username }}>
		{({ loading, data, error }) => {
			if (loading) {
				return (
					<GridContainer justify="center">
						<GridItem xs={10} sm={8} md={8}>
							<ScreenLoading />
						</GridItem>
					</GridContainer>
				);
			}
			if (error) {
				return (
					<div>
						<Errorpage />
					</div>
				);
			}
			return (
				<div>
					{data.getAllUnverifiedEmployer === null ? (
						<DataNotFound
							message={"There is no Unverified Employers Exits"}
							backbutton={false}
						/>
					) : (
						<UnverifiedEmployerList
							key={data.getMyAllEmployees}
							UnverifiedEmployer={data.getAllUnverifiedEmployer}
							history={history}
							client={client}
						/>
					)}
				</div>
			);
		}}
	</Query>
);

class UnverifiedEmployer extends React.Component {
	render() {
		return (
			<ApolloConsumer>
				{client => (
					<UnverifiedEmployerquery
						history={this.props.history}
						client={client}
					/>
				)}
			</ApolloConsumer>
		);
	}
}

export default UnverifiedEmployer;
