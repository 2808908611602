import gql from 'graphql-tag';
const EmployeeProfileQuery = gql`
	query EmployeeQuery($username: String!, $employee_username: String!) {
		getSingleEmployee(
			username: $username
			employee_username: $employee_username
		) {
			username
			name
			aadhar_number
			aadhar_verified
			email
			company_name
			designation
			department
			phone_number
			bucket_id
			hyperledger_id
			gender
			birthdate
			address
			profile_status
			profile_image_hash
			reason
			created_at
			created_by
			updated_at
			updated_by
			__typename
		}
		__typename
	}
`;
export default EmployeeProfileQuery;
