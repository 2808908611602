import React from "react";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import { ApolloConsumer } from "react-apollo";
import ReactCardFlip from "react-card-flip";
import ChartistGraph from "react-chartist"; // react plugin for creating charts
import { colouredLinesChart } from "variables/charts.jsx";
import axios from "axios";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

// @material-ui/icons
import Linkicon from "@material-ui/icons/Link";
import Lock from "@material-ui/icons/Lock";
import Timeline from "@material-ui/icons/Timeline";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardText from "components/Card/CardText.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
//validation
import validation from "../Validation/ProfileValidation.jsx";
//Custom-Component
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import InfoArea from "Custom-component/InfoArea/InfoArea.jsx";
import LoaderButton from "Custom-component/LoaderButton";

// Styles
import dashboardStyle from "../CustomStyles/dashboardstyle";
import "./reactFlipStyle.css";
import config from "../../config";
import history from "../../history";

class Dashboardpage extends React.Component {
	constructor(props) {
		super(props);
		const {
			EmployeeCount,
			EmployerCount,
			BlockedEmployeesCount,
			BlockedEmployersCount
		} = this.props;

		this.state = {
			alert: "",
			isFlipped: false,
			onboardcardFlip: false,
			requestsuccess: false,
			onboardrequestsuccess: false,
			employer_username: "",
			isLoading: false,
			//Statistics count
			EmployeeCountOnGvp: EmployeeCount.count,
			EmployerCountOnGvp: EmployerCount.count,
			UnverifiedEmployerCountOnGvp: BlockedEmployeesCount.count,
			TotalVerifiedDocumentOnGvp: BlockedEmployersCount.count,
			Revoke_User_Username: "",
			Revoke_User_UsernameState: "",
			Revoke_User_UsernameErrMsg: "",
			revoke_reason: "",
			admin_unblockemployee_reasonState: "",
			admin_unblockemployee_reasonErrMsg: "",
			usertype_display: "",
			usertype_name: "",
			admin_unblockemployee_reason: "",
			showuserrevokedetails: true,
			successalert: false,
			failalert: false,
			errormessage: "",
			successmessage: ""
		};
	}

	//Onchanging event
	change_dashboard(event, stateName, type) {
		switch (type) {
			case "revoke_username":
				var username_revoke_validation = validation.verifyUsername(
					event.target.value,
					stateName
				);
				if (!username_revoke_validation[stateName]) {
					this.setState({ [stateName + "State"]: "success" });
				} else {
					this.setState({
						[stateName + "State"]: "error",
						[stateName + "ErrMsg"]: username_revoke_validation[stateName]
					});
				}
				break;
			case "textarea_revoke":
				var reason_revoke_validation = validation.verifyTextarea(
					event.target.value,
					stateName
				);
				if (!reason_revoke_validation[stateName]) {
					this.setState({ [stateName + "State"]: "success" });
				} else {
					this.setState({
						[stateName + "State"]: "error",
						[stateName + "ErrMsg"]: reason_revoke_validation[stateName]
					});
				}
				break;

			default:
				break;
		}
		//assign the value to the state variable
		this.setState({ [stateName]: event.target.value });
	}

	// onchange function for input fields
	change_employee_unblock(event, stateName, type) {
		switch (type) {
			case "textarea_employee_unblock":
				var reason_employee_validation = validation.verifyTextarea(
					event.target.value,
					stateName
				);

				if (!reason_employee_validation[stateName]) {
					this.setState({ [stateName + "State"]: "success" });
				} else {
					this.setState({
						[stateName + "State"]: "error",
						[stateName + "ErrMsg"]: reason_employee_validation[stateName]
					});
				}
				break;

			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	}

	// validate the inputs on submit
	validate = () => {
		if (
			this.state.Revoke_User_UsernameState === "success" &&
			this.state.admin_unblockemployee_reasonState === "success" &&
			this.state.usertype_display !== ""
		) {
			return false;
		} else {
			return true;
		}
	};

	//enable the onboard employee button
	isValidated() {
		if (
			this.state.Revoke_User_EmailState === "success" &&
			this.state.employee_nameState !== "error" &&
			this.state.employee_aadhaar_numberState !== "error"
		) {
			return false;
		} else {
			return true;
		}
	}
	//function to select the duration
	handleSelectUserType = event => {
		this.setState({
			usertype_display: event.target.value
			//usertype_name: event.target.value
		});
	};

	// Function to Unrevoke user
	handlerevokeuser = async event => {
		if (!this.validate()) {
			// const { blocked_employee_data } = this.props;
			this.setState({ isLoading: true });
			if (
				this.state.admin_unblockemployee_reason !== "" &&
				this.state.admin_unblockemployee_reasonState === "success"
			) {
				try {
					const Employee_block_payload = {
						employee_username: this.state.Revoke_User_Username,
						profile_status: "Blocked",
						reason: this.state.admin_unblockemployee_reason
					};

					var headers = {
						Authorization: localStorage.getItem("accessToken"),
						"Content-Type": "application/json"
					};
					if (this.state.usertype_display === "employee") {
						axios
							.post(config.Cognito.Employee_action, Employee_block_payload, {
								headers: headers
							})
							.then(response => {
								if (response.data.data !== null) {
									this.setState({
										successalert: true,
										successmessage: "User blocked Successfully",
										isLoading: false,
										Revoke_User_Username: "",
										admin_unblockemployee_reason: "",
										Revoke_User_UsernameState: "",
										admin_unblockemployee_reasonState: "",
										usertype_display: ""
									});
								} else {
									this.setState({
										failalert: true,
										errormessage: response.data.errors.message,
										isLoading: false
									});
								}
							})
							.catch(err => {
								if (err.response.status === 401) {
									localStorage.clear();
									history.push("/auth/login");
								} else {
									this.setState({
										failalert: true,
										errormessage: "Failed to block User. Try Again",
										isLoading: false
									});
								}
							});
					} else {
						const Employer_block_payload = {
							employer_username: this.state.Revoke_User_Username,
							profile_status: "Blocked",
							reason: this.state.admin_unblockemployee_reason
						};
						axios
							.post(config.Cognito.Employer_action, Employer_block_payload, {
								headers: headers
							})
							.then(response => {
								if (response.data.data !== null) {
									this.setState({
										Revoke_User_Username: "",
										admin_unblockemployee_reason: "",
										Revoke_User_UsernameState: "",
										usertype_display: "",
										admin_unblockemployee_reasonState: "",
										successalert: true,
										successmessage: "User blocked Successfully",
										isLoading: false
									});
								} else {
									this.setState({
										failalert: true,
										errormessage: response.data.errors.message,
										isLoading: false
									});
								}
							})
							.catch(err => {
								this.setState({
									failalert: true,
									errormessage: "Failed to block User.Please Try Again.",
									isLoading: false
								});
							});
					}
				} catch (e) {
					this.setState({
						failalert: true,
						errormessage: "Failed to block User.Please Try Again.",
						isLoading: false
					});
				}
			} else {
				this.setState({
					admin_unblockemployee_reasonState: "error",
					admin_unblockemployee_reasonErrMsg: "Reason is required"
				});
			}
		}
	};

	render() {
		const { classes } = this.props;

		return (
			<div>
				<Snackbar
					place="bc"
					color="danger"
					icon={AddAlert}
					message={this.state.failalert ? <b>{this.state.errormessage}</b> : ""}
					open={this.state.failalert}
					closeNotification={() => this.setState({ failalert: false })}
					close
				/>
				<Snackbar
					place="bc"
					color="info"
					icon={AddAlert}
					message={
						this.state.successalert ? <b>{this.state.successmessage}</b> : ""
					}
					open={this.state.successalert}
					closeNotification={() => this.setState({ successalert: false })}
					close
				/>
				<ApolloConsumer>
					{client => (
						<div>
							<GridContainer>
								{this.state.alert}
								{/* Document request count statistics */}
								<GridItem xs={12} sm={6} md={6} lg={3}>
									<Card>
										<CardHeader color="info" stats icon>
											<CardIcon color="info">
												<Icon>
													{" "}
													<i className="fa fa-users" />
												</Icon>
											</CardIcon>
											<p className={classes.cardCategory}>Employee Count</p>
											<h3 className={classes.cardCategory}>
												{this.state.EmployeeCountOnGvp}
											</h3>
										</CardHeader>
										<CardFooter stats>
											<div className={classes.stats}>
												<Icon>info</Icon>
												<Link
													to={{
														pathname: "/employees",
														state: ""
													}}
													className={classes.a}
												>
													More Info
												</Link>
											</div>
										</CardFooter>
									</Card>
								</GridItem>
								{/* Document Approve by employer statistics */}
								<GridItem xs={12} sm={6} md={6} lg={3}>
									<Card>
										<CardHeader color="success" stats icon>
											<CardIcon color="success">
												<Icon>
													{" "}
													<i className="fa fa-building" />
												</Icon>
											</CardIcon>
											<p className={classes.cardCategory}>Authority Count</p>
											<h3 className={classes.cardCategory}>
												{this.state.EmployerCountOnGvp}
											</h3>
										</CardHeader>
										<CardFooter stats>
											<div className={classes.stats}>
												<Icon>info</Icon>
												<Link
													to={{
														pathname: "/employers",
														state: ""
													}}
													className={classes.a}
												>
													More Info
												</Link>
											</div>
										</CardFooter>
									</Card>
								</GridItem>
								{/* Document rejected by employer count statistics */}
								<GridItem xs={12} sm={6} md={6} lg={3}>
									<Card>
										<CardHeader color="danger" stats icon>
											<CardIcon color="danger">
												<Icon>
													{" "}
													<i className="fa fa-user-times" />
												</Icon>
											</CardIcon>
											<p className={classes.cardCategory}>Blocked Employees</p>
											<h3 className={classes.cardCategory}>
												{this.state.UnverifiedEmployerCountOnGvp}
											</h3>
										</CardHeader>
										<CardFooter stats>
											<div className={classes.stats}>
												<Icon>info</Icon>
												<Link
													to={{
														pathname: "/employees",
														state: "Blocked"
													}}
													className={classes.a}
												>
													More Info
												</Link>
											</div>
										</CardFooter>
									</Card>
								</GridItem>
								{/* Document request Pending count statistics */}
								<GridItem xs={12} sm={6} md={6} lg={3}>
									<Card>
										<CardHeader color="warning" stats icon>
											<CardIcon color="warning">
												<Icon>
													<i className="fa fa-user-times" />
												</Icon>
											</CardIcon>
											<p className={classes.cardCategory}>
												Blocked Authorities
											</p>
											<h3 className={classes.cardCategory}>
												{this.state.TotalVerifiedDocumentOnGvp}
											</h3>
										</CardHeader>
										<CardFooter stats>
											<div className={classes.stats}>
												<Icon>info</Icon>
												<Link
													to={{
														pathname: "/employers",
														state: "Blocked"
													}}
													className={classes.a}
												>
													More Info
												</Link>
											</div>
										</CardFooter>
									</Card>
								</GridItem>
							</GridContainer>
							{/* Timeline Chart */}
							<GridContainer justify="center">
								<GridItem xs={12} sm={12} md={12}>
									<Card>
										<CardHeader color="warning" icon>
											kljklk
											<CardIcon color="warning">
												<Timeline />
											</CardIcon>
											<h4 className={classes.cardIconTitle}>
												API BILLINGS CHARTS
											</h4>
										</CardHeader>
										<CardBody>
											<ChartistGraph
												data={colouredLinesChart.data}
												type="Line"
												options={colouredLinesChart.options}
												listener={colouredLinesChart.animation}
											/>
										</CardBody>
									</Card>
								</GridItem>
							</GridContainer>
							{/* Onboarding Employee by the employer */}
							<GridContainer justify="center">
								<GridItem xs={12} sm={12} md={12}>
									<Card>
										<CardHeader color="info" text>
											<CardText color="info">
												<h4 className={classes.cardIconTitle.color}>
													<b>Block Users</b>
												</h4>
											</CardText>
										</CardHeader>
										<CardBody className={classes.cardStyle}>
											<GridContainer justify="center">
												<GridItem xs={12} sm={12} md={5}>
													<InfoArea
														title="Revoke Employee"
														description="We've created the marketing campaign of the website. It was a very interesting collaboration."
														icon={Linkicon}
														iconColor="rose"
													/>
													<InfoArea
														title="Revoke Authority"
														description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."
														icon={Lock}
														iconColor="primary"
													/>
													<InfoArea
														title="Reason to Revoke"
														description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."
														icon={Lock}
														iconColor="primary"
													/>
												</GridItem>

												<GridItem xs={12} sm={12} md={5}>
													<ReactCardFlip isFlipped={this.state.onboardcardFlip}>
														<Card key="front" className={classes.cardSignup}>
															<h4 className={classes.cardTitle}>
																<b>User Details</b>
															</h4>
															<CardBody>
																{this.state.showuserrevokedetails ? (
																	<GridContainer justify="center">
																		<GridItem xs={12} sm={12} md={10}>
																			<CustomInput
																				success={
																					this.state
																						.Revoke_User_UsernameState ===
																					"success"
																				}
																				error={
																					this.state
																						.Revoke_User_UsernameState ===
																					"error"
																				}
																				labelText="Username*"
																				id="Revoke_User_Username"
																				formControlProps={{
																					fullWidth: true,
																					className:
																						classes.customFormControlClasses
																				}}
																				helpText={
																					this.state
																						.Revoke_User_UsernameState ===
																					"error"
																						? this.state
																								.Revoke_User_UsernameErrMsg
																						: ""
																				}
																				inputProps={{
																					onChange: event =>
																						this.change_dashboard(
																							event,
																							"Revoke_User_Username",
																							"revoke_username"
																						),
																					value: this.state.Revoke_User_Username
																				}}
																			/>
																			<FormControl
																				fullWidth
																				className={classes.selectFormControl}
																			>
																				<InputLabel
																					FormLabelClasses={{
																						root: classes.cssLabel,
																						focused: classes.cssFocused
																					}}
																				>
																					User Type*
																				</InputLabel>

																				<Select
																					MenuProps={{
																						className: classes.selectMenu
																					}}
																					classes={{
																						select: classes.select
																					}}
																					value={this.state.usertype_display}
																					onChange={this.handleSelectUserType}
																					input={
																						<Input
																							classes={{
																								underline: classes.underline
																							}}
																							id="select-single-chip"
																						/>
																					}
																				>
																					{/* <Select
																				MenuProps={{
																					className: classes.selectMenu
																				}}
																				classes={{
																					select: classes.select
																				}}
																				value={this.state.employer_username}
																				onChange={this.handleSelectCompany}
																				inputProps={{
																					name: "employer_username",
																					id: "Choose_Employer_Type"
																				}}
																			> */}
																					{/* <MenuItem
																					disabled
																					classes={{
																						root: classes.selectMenuItem
																					}}
																				>
																					Select User Type
																				</MenuItem> */}

																					<MenuItem
																						classes={{
																							root: classes.selectMenuItem,
																							selected:
																								classes.selectMenuItemSelected
																						}}
																						value="employee"
																					>
																						Employee
																					</MenuItem>
																					<MenuItem
																						classes={{
																							root: classes.selectMenuItem,
																							selected:
																								classes.selectMenuItemSelected
																						}}
																						value="employer"
																					>
																						Authority
																					</MenuItem>
																				</Select>
																			</FormControl>

																			<CustomInput
																				success={
																					this.state
																						.admin_unblockemployee_reasonState ===
																					"success"
																				}
																				error={
																					this.state
																						.admin_unblockemployee_reasonState ===
																					"error"
																				}
																				labelText={
																					<span>Reason to block*</span>
																				}
																				id="Admin_Unblockuser_reason"
																				formControlProps={{
																					fullWidth: true
																				}}
																				helpText={
																					this.state
																						.admin_unblockemployee_reasonState ===
																					"error"
																						? this.state
																								.admin_unblockemployee_reasonErrMsg
																						: null
																				}
																				inputProps={{
																					multiline: true,
																					rows: 2,
																					onChange: event =>
																						this.change_employee_unblock(
																							event,
																							"admin_unblockemployee_reason",
																							"textarea_employee_unblock",
																							3
																						),
																					value: this.state
																						.admin_unblockemployee_reason
																				}}
																			/>

																			<div className={classes.center}>
																				{/* <Button
																				color="info"
																				className={classes.center}
																				id="Revoke_User_Click"
																				onClick={() => this.handlerevokeuser()}
																			> */}
																				<LoaderButton
																					id="Revoke_User_Click"
																					type="submit"
																					onClick={this.handlerevokeuser}
																					disabled={this.validate()}
																					color="info"
																					round
																					isLoading={this.state.isLoading}
																					text="Block"
																					loadingText="Processing..."
																				/>
																				{/* REVOKE &nbsp;
																				<Icon className={classes.icons}>
																					send
																				</Icon>
																			</Button> */}
																			</div>
																		</GridItem>
																	</GridContainer>
																) : null}
															</CardBody>
														</Card>
														<Card key="back" className={classes.cardSignup}>
															{this.state.onboardrequestsuccess ? (
																<CardBody>
																	<GridContainer justify="center">
																		<GridItem xs={12} sm={12} md={10}>
																			<div className={classes.center}>
																				<h3 className={classes.cardTitle}>
																					Request sent Successfully..
																				</h3>
																				<Icon className={classes.successicons}>
																					check_circle
																				</Icon>
																				<p />
																				<Button
																					size="sm"
																					color="success"
																					className={classes.center}
																					id="Employer_request_employee_success"
																					onClick={this.handleOnboardflipClose}
																				>
																					SUCCESS &nbsp;
																				</Button>
																			</div>
																		</GridItem>
																	</GridContainer>
																</CardBody>
															) : (
																<CardBody>
																	<GridContainer justify="center">
																		<GridItem xs={12} sm={12} md={10}>
																			<div className={classes.center}>
																				<h3 className={classes.cardTitle}>
																					Check Employee Email Address!!
																				</h3>
																				<Icon className={classes.rejecticons}>
																					cancel
																				</Icon>
																				<p />
																				<Button
																					size="sm"
																					color="danger"
																					className={classes.center}
																					id="Onboard_employee_request_reject"
																					onClick={this.handleOnboardflipClose}
																				>
																					Retry &nbsp;{" "}
																					<Icon className={classes.rejecticons}>
																						refresh
																					</Icon>
																				</Button>
																			</div>
																		</GridItem>
																	</GridContainer>
																</CardBody>
															)}
														</Card>
													</ReactCardFlip>
												</GridItem>
											</GridContainer>
										</CardBody>
									</Card>
								</GridItem>{" "}
							</GridContainer>
						</div>
					)}
				</ApolloConsumer>
			</div>
		);
	}
}

Dashboardpage.propTypes = {
	classes: propTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboardpage);
