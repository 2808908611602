import gql from "graphql-tag";
const StatisticsCountQuerry = gql`
	query dashboardCountQuery($username: String) {
		employeeCount(username: $username) {
			count
		}
		employerCount(username: $username) {
			count
		}
		blockedEmployeesCount(username: $username) {
			count
		}
		blockedEmployersCount(username: $username) {
			count
		}
	}
`;
export default StatisticsCountQuerry;
