import gql from "graphql-tag";
const DocumentRequestedQuery = gql`
	query EmploymentQuery($username: String!, $employer_username: String) {
		documentRequest(
			username: $username
			employer_username: $employer_username
		) {
			employment {
				username
				file_id
				file_hash
				is_verified_employer
				document_name
				document_extension
				document_upload_type
				employee_id
				employer_name
				employer_mail_id
				organization_name
				joining_date
				end_date
				experience
				designation
				department
				uploaded_by
				uploaded_at
				modified_at
				verified_status
				approved_by
				approver_email
			}
			__typename
		}
		__typename
	}
`;
export default DocumentRequestedQuery;
