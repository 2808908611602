import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { graphql } from "react-apollo";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import ReactLoading from "react-loading";
import Notifications from "@material-ui/icons/Notifications";

import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
// core components
import HeaderLinkQuery from "../Header/HeaderLinkQuery.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "./headerStyle.jsx";
//Graphql and Mutation Query
import Notificationquery from "../../Graphql/Querries/Notificationquery";
import MarkNotificationReadMutation from "../../Graphql/Querries/MarkNotificationreadMutation";

// function Header({ ...props }) {
class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			alert: null,
			NotificationList: null,
			isloading: false,
			isError: false
		};
	}
	makeBrand() {
		const props = this.props;

		var name;
		props.routes.map((prop, key) => {
			if (prop.collapse) {
				prop.views.map((prop, key) => {
					if (prop.path === props.location.pathname) {
						name = prop.name;
					}
					return null;
				});
			}
			if (prop.path === props.location.pathname) {
				name = prop.name;
			}
			return null;
		});
		return name;
	}
	async componentDidMount() {
		await this.props.client
			.query({
				query: Notificationquery,
				variables: {
					username: localStorage.getItem("username")
				}
			})
			.then(response => {
				if (response.data.getNotification !== null)
					this.setState({
						NotificationList: response.data.getNotification.notifications,
						isError: false
					});
			})
			.catch(err => {
				this.setState({ isError: true });
			});
	}

	back = event => {
		const { NotificationList } = this.state;

		if (
			(NotificationList &&
				NotificationList.length === 1 &&
				event.notifications === null) ||
			(NotificationList &&
				event.notifications &&
				NotificationList.length !== event.notifications.length)
		) {
			this.setState({ NotificationList: event.notifications, isError: false });
		} 
	};
	notifyClick = async (url, id) => {
		await this.props
			.mutate({
				variables: {
					username: localStorage.getItem("username"),
					id: id
				},
				refetchQueries: [
					{
						query: Notificationquery,
						variables: {
							username: localStorage.getItem("username")
						}
					}
				]
			})
			.then(response => {
				this.setState({ open: false, isError: false });
				if (url !== "") {
					this.props.history.push(url);
				}
			})
			.catch(err => {
				this.setState({
					isloading: false,
					isError: true
				});
			});
	};
	// handle logout failure
	warningWithConfirmMessage = err => {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ display: "block", marginTop: "-100px", color: "black" }}
					title="Please try again"
					onConfirm={() => this.failhideAlert}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.danger
					}
				>
					{err.message}
				</SweetAlert>
			)
		});
	};
	handleNotificationClick = async () => {
		this.setState({ open: !this.state.open });
	};
	handleClose = () => {
		this.setState({ open: false });
	};

	render() {
		const { classes, color, rtlActive } = this.props;
		const { NotificationList, isError, open, isloading } = this.state;
		const appBarClasses = cx({
			[" " + classes[color]]: color
		});
		const sidebarMinimize =
			classes.sidebarMinimize +
			" " +
			cx({
				[classes.sidebarMinimizeRTL]: rtlActive
			});
		const dropdownItem = cx(classes.dropdownItem, classes.infoHover, {
			[classes.dropdownItemRTL]: rtlActive
		});
		const managerClasses = cx({
			[classes.managerClasses]: true
		});

		return (
			<AppBar className={classes.appBar + appBarClasses}>
				<Toolbar className={classes.container}>
					<Hidden smDown implementation="css">
						<div className={sidebarMinimize}>
							{this.props.miniActive ? (
								<Button
									justIcon
									round
									color="white"
									onClick={this.props.sidebarMinimize}
								>
									<ViewList className={classes.sidebarMiniIcon} />
								</Button>
							) : (
								<Button
									justIcon
									round
									color="white"
									onClick={this.props.sidebarMinimize}
								>
									<MoreVert className={classes.sidebarMiniIcon} />
								</Button>
							)}
						</div>
					</Hidden>
					<div className={classes.flex}>
						{/* Here we create navbar brand, based on route name */}
						<Button href="#" className={classes.title} color="transparent">
							{this.makeBrand()}
						</Button>
					</div>
					<Hidden smDown implementation="css">
						<HeaderLinkQuery
							rtlActive={rtlActive}
							history={this.props.history}
							client={this.props.client}
							back={this.back}
						/>
					</Hidden>
					<Hidden mdUp implementation="css">
						<div className={managerClasses}>
							<Button
								color="transparent"
								justIcon
								aria-label="Notifications"
								aria-owns={open ? "menu-list" : null}
								aria-haspopup="true"
								onClick={this.handleNotificationClick}
								className={
									rtlActive ? classes.buttonLinkRTL : classes.buttonLink
								}
								muiClasses={{
									label: rtlActive ? classes.labelRTL : ""
								}}
								buttonRef={node => {
									this.anchorEl = node;
								}}
							>
								<Notifications
									className={
										classes.headerLinksSvg +
										" " +
										(rtlActive
											? classes.links + " " + classes.linksRTL
											: classes.links)
									}
								/>
								{isError ? (
									<span className={classes.warningNotification}>!</span>
								) : NotificationList === null ? (
									""
								) : (
									<span className={classes.notifications}>
										{NotificationList.length}
									</span>
								)}
							</Button>
							<Popper
								open={open}
								anchorEl={this.anchorEl}
								transition
								disablePortal
								placement="bottom-end"
								className={cx({
									[classes.popperClose]: !open,
									[classes.pooperResponsive]: true,
									[classes.pooperNav]: true
								})}
								modifiers={{
									arrow: {
										enabled: true,
										element: "node"
									}
								}}
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										id="menu-list"
										style={{ transformOrigin: "0 0 0" }}
									>
										<Paper className={classes.dropdown}>
											<ClickAwayListener onClickAway={this.handleClose}>
												<MenuList role="menu">
													{isloading ? (
														<MenuItem className={dropdownItem} disabled>
															<ReactLoading
																type={"spinningBubbles"}
																color={"#000"}
																height={100}
																width={100}
															/>
														</MenuItem>
													) : isError ? (
														<MenuItem className={dropdownItem} disabled>
															{
																"Something went wrong. Please try after some time"
															}
														</MenuItem>
													) : NotificationList === null ? (
														<MenuItem className={dropdownItem} disabled>
															{"No Notification found"}
														</MenuItem>
													) : (
														NotificationList.map((prop, key) => (
															<MenuItem
																key={key}
																className={dropdownItem}
																onClick={() =>
																	this.notifyClick(prop.action_url, prop.id)
																}
															>
																{prop.message}
															</MenuItem>
														))
													)}
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>

							<Button
								className={classes.appResponsive}
								color="transparent"
								justIcon
								aria-label="open drawer"
								onClick={this.props.handleDrawerToggle}
							>
								<Menu />
							</Button>
						</div>
					</Hidden>
				</Toolbar>
			</AppBar>
		);
	}
}
Header.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
	rtlActive: PropTypes.bool
};

// export default withStyles(headerStyle)(Header);
const headerwithstyle = withStyles(headerStyle)(Header);
export default graphql(MarkNotificationReadMutation)(headerwithstyle);
