import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
//material ui components
import withStyles from "@material-ui/core/styles/withStyles";
//styles
import userProfileStyles from "./CustomStyles/profilepagestyle";

class WarningAlert extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: ""
		};
	}
	// close failure alert
	closeAlert = () => {
		this.setState({
			alert: null
		});
	};
	render() {
		return (
			<div>
				{this.state.alert !== null ? (
					<SweetAlert
						warning
						style={{ display: "block", marginTop: "-100px", color: "#000000" }}
						title="Failed"
						onConfirm={() => this.closeAlert()}
						confirmBtnCssClass={
							this.props.classes.button + " " + this.props.classes.danger
						}
						confirmBtnText={this.props.confirmBtnText}
					>
						{this.props.errorMessage}
					</SweetAlert>
				) : null}
				{this.state.alert}
			</div>
		);
	}
}

export default withStyles(userProfileStyles)(WarningAlert);
