// ##############################
// // // RegisterPage view styles
// #############################
import notificationStyle from "./notificationsStyle.jsx";
import modalStyle from "./modalStyle";

const errorPagestyle = {
  container: {
    // background: "#010C4E !important",
    // textAlign: "center",
    position: "relative",
    zIndex: "3",
    paddingTop: "15px",
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    height:"auto",
    width:"100%",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    }
  },  
  cardClasses: {
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    marginBottom: "100px",
    padding: "10px 10px !important",
    textAlign: "center",
    display: "inline-block",
    position: "relative",
    width: "100%",
    height:"100%",
    margin: "0 0 !important",
    // boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    borderRadius: "6px",
    color: "rgba(0, 0, 0, 0.87)",
    background: "#2DBEFF !important"
  },
  center: {
    textAlign: "center",
    // border:"1px solid gray",
    // borderRadius:"7%",
    // fontSize:"0.875",
    // wordWrap: "break-word",
    // width: "100px", // only for IE8
    // maxWidth: "100%"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  form: {
    padding: "0 20px",
    position: "relative"
  },
  img: {
    maxWidth: "100%",
    height: "auto",
    backgroundSize: "cover",
    top:"50%",
    left:"50%",
    border: "0"
  },
 
  customFormControlClasses: {
    margin: "0 12px"
  },
  checkboxLabelControl: {
    margin: "0"
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(0, 0, 0, 0.26)"
  },
  ErrorPagecardTitl707: {
    color: "#3C4858",
    fontSize: "180px",
    marginTop: 0,
    marginBottom: "3px"
  },
  cardTitle: {
    fontSize: "3.6em",
    fontWeight:"bold",
    color:"#ffffff",
  },
  ...notificationStyle,
  ...modalStyle
};

export default errorPagestyle;
