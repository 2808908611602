import gql from "graphql-tag";
const MarkNotificationReadMutation = gql`
mutation NotificationQuery($username: String!,$id:Int!){
    markNotificationRead(username: $username, id: $id) {
      message
      __typename
    }
    __typename
  }
`;
export default MarkNotificationReadMutation;
