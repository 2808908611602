import React from "react";
import MUIDataTable from "mui-datatables";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import { MuiThemeProvider } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// file imports
import EmployeeViews from "./UnverifiedEmployerViews.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
//styles
import Mytheme from "../CustomStyles/customMuithemeTable";

// Date formater
var dateFormat = require("dateformat");

const styles = {
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px"
	}
};

class UnverifiedEmployerList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			unverified_Employer_tabledata: [],

			show_employee_list: true,
			show_employee_view: false,
			show_employee_profile: false,
			employee_employment_data: []
		};
	}

	handleBack = () => {
		this.setState({
			show_employee_list: true,
			show_employee_view: false,
			show_employee_profile: false
		});
	};
	componentDidCatch(error, info) {
		if (
			error.toString().includes("greater than the total available page length")
		) {
			this.setState({ page: 0 });
		} else {
			this.setState({ hashError: true });
		}
	}
	handleEmployeeProfile = () => {
		this.setState({
			show_employee_list: false,
			show_employee_view: false,
			show_employee_profile: true
		});
	};
	componentDidMount() {
		var unverified_Employer_tabledata = [];
		var self = this;

		const { UnverifiedEmployer } = this.props;
		var unverified_Employer_list = UnverifiedEmployer.UnverifiedEmployer;

		//for grid view element should be in same order column and the data pushed here should be same count.
		Object.keys(unverified_Employer_list).forEach(function(key) {
			unverified_Employer_tabledata.push([
				unverified_Employer_list[key].name === ""
					? "-"
					: unverified_Employer_list[key].name, //email should replace here

				unverified_Employer_list[key].company_name === ""
					? "-"
					: unverified_Employer_list[key].company_name,

				unverified_Employer_list[key].ip,
				dateFormat(
					new Date(unverified_Employer_list[key].created_at),
					"dd/mm/yyyy HH:MM:ss"
				)

				// <div className="actions-right">
				//   {/* Icon button to view the employment data in details */}
				//   <Tooltip
				//     id="tooltip-top"
				//     title="View Profile"
				//     placement="bottom"
				//     classes={{ tooltip: classes.tooltip }}
				//   >
				//     <Button
				//       justIcon
				//       round
				//       simple
				//       onClick={() => {
				//         self.setState({
				//           employee_employment_data: unverified_Employer_list[key], //set the particular employee data in state variable employee employment data

				//           show_employee_list: false,
				//           show_employee_view: true
				//         });
				//       }}
				//       color="info"
				//       className="like"
				//     >
				//       <Visibility />
				//     </Button>
				//   </Tooltip>

				// </div>
			]);
		});

		self.setState({
			unverified_Employer_tabledata: unverified_Employer_tabledata
		});
	}
	render() {
		const { classes } = this.props;
		const options = {
			filterType: "dropdown",
			responsive: "stacked",
			fixedHeader: true,
			selectableRows: false,
			page: this.state.page
		};
		const columns = [
			{
				name: "Authority Name",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Organization Name",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "IP Address",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "URL Created At",
				options: {
					filter: true,
					sort: true
				}
			}
		];
		return (
			<GridContainer>
				<GridItem xs={12}>
					{this.state.show_employee_list ? (
						<Card>
							<CardHeader color="info" icon>
								<CardIcon color="info">
									<Icon>insert_link</Icon>
								</CardIcon>
								<h4 className={classes.cardIconTitle}>
									Unverified Authorities List
								</h4>
							</CardHeader>
							<CardBody>
								<MuiThemeProvider theme={Mytheme}>
									<MUIDataTable
										title={""}
										data={this.state.unverified_Employer_tabledata}
										columns={columns}
										options={options}
									/>
								</MuiThemeProvider>
							</CardBody>
						</Card>
					) : null}
					{this.state.show_employee_view ? (
						// Employee  Employment View Page
						<EmployeeViews
							handleback={this.handleBack}
							employee_employment_data={this.state.employee_employment_data}
						/>
					) : null}
				</GridItem>
			</GridContainer>
		);
	}
}

export default withStyles(styles)(UnverifiedEmployerList);
