import React from "react";
import MUIDataTable from "mui-datatables";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import { MuiThemeProvider } from "@material-ui/core/styles";

//core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

//styles
import userProfileStyles from "../CustomStyles/profilepagestyle.jsx";
import Mytheme from "../CustomStyles/customMuithemeTable.jsx";

const styles = {
	...userProfileStyles,

	cardShadow: {
		boxShadow:
			"0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
	},
	cardbox: {
		marginTop: "15px",
		padding: "40px 0px",
		borderRadius: "6px",
		marginBottom: "100px"
	},
	RegularButtonButton: {
		svg: {
			top: "0",
			/* width: 18px; */
			/* height: 18px; */
			display: "inline-block",
			position: "relative",
			marginRight: "4px",
			verticalAlign: "middle"
		}
	},

	blueAvatar: {
		color: "#FFFFFF",
		background: "linear-gradient(60deg, #ffdd68, #ffc90d)",
		boxShadow:
			"0 12px 20px -10px rgba(255, 201, 13, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 201, 13, 0.2)"
		// backgroundColor:'#33DDFF',
	},
	iconbutton: {
		color: "#33DDFF"
		// backgroundColor:'#33DDFF',
	},
	approveicon: {
		color: "green"
		// backgroundColor:'#33DDFF',
	},
	rejecticon: {
		color: "red"
		// backgroundColor:'#33DDFF',
	}
};
class UnverifiedEmployerViews extends React.Component {
	constructor(props) {
		super(props);
		// we use this to make the card to appear after the page has been rendered
		this.state = {
			showedit: true
		};
		this.editprofile = this.editprofile.bind(this);
	}

	handleChange = (event, value) => {
		this.setState({ value });
	};
	handleChangeIndex = index => {
		this.setState({ value: index });
	};
	componentDidCatch(error, info) {
		if (
			error.toString().includes("greater than the total available page length")
		) {
			this.setState({ page: 0 });
		} else {
			this.setState({ hashError: true });
		}
	}
	editprofile() {
		this.setState({
			showedit: true,
			showview: false
		});
	}
	render() {
		const { classes } = this.props;
		const options = {
			filterType: "dropdown",
			responsive: "stacked",
			fixedHeader: true,
			page: this.state.page,
			selectableRows: false
		};
		const columns = [
			{
				name: "Authority Name",
				options: {
					filter: true,
					sort: false
				}
			},
			{
				name: "Organization name",
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: "Email",
				options: {
					filter: true,
					sort: false
				}
			},
			{
				name: "Phone Number",
				options: {
					filter: true,
					sort: false
				}
			},
			{
				name: "No. of Document viewed",
				options: {
					filter: true,
					sort: false
				}
			},

			{
				name: "Action",
				options: {
					filter: true,
					sort: false
				}
			}
		];
		return (
			<div>
				{this.state.showedit ? (
					<div>
						<GridContainer justify="center">
							<GridItem xs={12} sm={12} md={11}>
								<Card className={classes.cardShadow}>
									<CardHeader color="info" icon>
										<CardIcon color="info">
											<Icon>perm_identity</Icon>
										</CardIcon>
										<h4 className={classes.cardIconTitle}>
											Unverified Authority Details
										</h4>
									</CardHeader>
									<CardBody>
										<GridContainer justify="center">
											<GridItem xs={12} sm={12} md={12}>
												<GridContainer justify="center">
													<GridItem xs={12} sm={12} md={6}>
														<Card className={classes.cardSignup}>
															<h4 className={classes.center}>
																Employment Details
															</h4>
															<CardBody>
																<GridContainer justify="center">
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>Document Name</h4>
																	</GridItem>
																	<GridItem xs={1} sm={1} md={1}>
																		<h4>:</h4>
																	</GridItem>
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>{""}</h4>
																	</GridItem>
																</GridContainer>
																<GridContainer justify="center">
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>Joining Date</h4>
																	</GridItem>
																	<GridItem xs={1} sm={1} md={1}>
																		<h4>:</h4>
																	</GridItem>
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>{""}</h4>
																	</GridItem>
																</GridContainer>
																<GridContainer justify="center">
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>Exit Date</h4>
																	</GridItem>
																	<GridItem xs={1} sm={1} md={1}>
																		<h4>:</h4>
																	</GridItem>
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>{""}</h4>
																	</GridItem>
																</GridContainer>
																<GridContainer justify="center">
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>Experience</h4>
																	</GridItem>
																	<GridItem xs={1} sm={1} md={1}>
																		<h4>:</h4>
																	</GridItem>
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>{""}</h4>
																	</GridItem>
																</GridContainer>
															</CardBody>
														</Card>
													</GridItem>
													<GridItem xs={12} sm={12} md={6}>
														<Card className={classes.cardSignup}>
															<h4 className={classes.center}>
																Employee details
															</h4>
															<CardBody>
																<GridContainer justify="center">
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>Employee Id</h4>
																	</GridItem>
																	<GridItem xs={1} sm={1} md={1}>
																		<h4>:</h4>
																	</GridItem>
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>{""}</h4>
																	</GridItem>
																</GridContainer>
																<GridContainer justify="center">
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>Employee Name</h4>
																	</GridItem>
																	<GridItem xs={1} sm={1} md={1}>
																		<h4>:</h4>
																	</GridItem>
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>{""}</h4>
																	</GridItem>
																</GridContainer>
																<GridContainer justify="center">
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>Designation</h4>
																	</GridItem>
																	<GridItem xs={1} sm={1} md={1}>
																		<h4>:</h4>
																	</GridItem>
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>{""}</h4>
																	</GridItem>
																</GridContainer>
																<GridContainer justify="center">
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>Department</h4>
																	</GridItem>
																	<GridItem xs={1} sm={1} md={1}>
																		<h4>:</h4>
																	</GridItem>
																	<GridItem xs={4} sm={4} md={4}>
																		<h4>{""}</h4>
																	</GridItem>
																</GridContainer>
															</CardBody>
														</Card>
													</GridItem>
												</GridContainer>
											</GridItem>
										</GridContainer>

										<MuiThemeProvider theme={Mytheme}>
											<MUIDataTable
												title={""}
												data={this.state.employee_tabledata}
												columns={columns}
												options={options}
											/>
										</MuiThemeProvider>

										<Clearfix />
									</CardBody>
									<CardFooter className={classes.justifyContentCenter}>
										<Button color="info" onClick={this.props.handleback}>
											Back
										</Button>
									</CardFooter>
								</Card>
							</GridItem>
						</GridContainer>
					</div>
				) : null}
			</div>
		);
	}
}
export default withStyles(styles)(UnverifiedEmployerViews);
