import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert"; // react component used to create sweet alerts
import { connect } from "react-redux";
import axios from "axios";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Lock from "@material-ui/icons/Lock";

//images
import Robot from "assets/img/robot.png";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// Custom components
import CustomInput from "../../Custom-component/CustomInput/CustomInput.jsx";
import LoaderButton from "../../Custom-component/LoaderButton";
// styles
import loginPageStyle from "./loginPageStyle.jsx";
// file imports
import config from "../../config";
import WarningAlert from "../WarningAlert.jsx";
// vaidation
import validation from "../Validation/ProfileValidation.jsx";
// Encryption plugin
var sha256 = require("js-sha256");

class LoginPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cardAnimaton_login: "cardHidden",
			admin_login_username: "",
			admin_login_usernameState: "",
			admin_login_password: "",
			admin_login_passwordState: "",
			admin_login_usernameErrMsg: "",
			admin_login_passwordErrMsg: "",
			isLoading: false,
			alert: null,
			openWarning_login: false,
			user_data: {}
		};
	}
	autoCloseAlert_login = () => {
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: "block", marginTop: "-100px", color: "#000000" }}
					title="Login Successfull!"
					onConfirm={() => this.successhideAlert_login()}
					showConfirm={false}
				>
					<h4>Welcome !!</h4>
				</SweetAlert>
			)
		});
		setTimeout(this.successhideAlert_login, 2000);
	};
	successhideAlert_login = () => {
		this.setState({
			alert: null
		});
		this.props.history.push("/dashboard");
	};
	login_change(event, stateName, type) {
		switch (type) {
			case "username_login":
				var username_login_validation = validation.verifyUsername(
					event.target.value,
					stateName
				);
				if (!username_login_validation[stateName]) {
					this.setState({ [stateName + "State"]: "success" });
				} else {
					this.setState({
						[stateName + "State"]: "error",
						[stateName + "ErrMsg"]: username_login_validation[stateName]
					});
				}
				break;
			case "password_login":
				var password_login_validation = validation.password_validate(
					event.target.value,
					stateName
				);
				if (!password_login_validation[stateName]) {
					this.setState({ [stateName + "State"]: "success" });
				} else {
					this.setState({
						[stateName + "State"]: "error",
						[stateName + "ErrMsg"]: password_login_validation[stateName]
					});
				}
				break;
			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	}
	LoginClick = async event => {
		event.preventDefault();
		var user_data = {};
		this.setState({ isLoading: true, openWarning_login: false });
		if (
			this.state.admin_login_username !== "" &&
			this.state.admin_login_usernameState !== "error" &&
			this.state.admin_login_password !== "" &&
			this.state.admin_login_passwordState !== "error"
		) {
			try {
				const signin_payload = {
					username: this.state.admin_login_username,
					password: sha256(this.state.admin_login_password)
				};
				var headers = {
					"Content-Type": "application/json"
				};
				axios
					.post(
						config.Cognito.signin_url,
						signin_payload,
						{ crossdomain: true },
						{ headers: headers }
					)
					.then(response => {
						if (response.data.data === null) {
							localStorage.setItem("userlogged", false);
							this.setState({ isLoading: false, openWarning_login: true });
						} else {
							user_data = {
								accessToken: response.data.data.AccessToken,
								idToken: response.data.data.IdToken,
								refreshToken: response.data.data.RefreshToken,
								username: this.state.admin_login_username
							};

							localStorage.setItem(
								"accessToken",
								response.data.data.AccessToken
							);
							localStorage.setItem("useremail", response.data.data.Email);
							localStorage.setItem("username", this.state.admin_login_username);
							this.props.getuserdata(user_data);
							this.autoCloseAlert_login(response.data.Name);
							localStorage.setItem("userlogged", true);
							this.setState({ isLoading: false });
						}
					})
					.catch(err => {
						localStorage.setItem("userlogged", false);
						this.setState({
							isLoading: false,
							openWarning_login: true
						});
					});
			} catch (e) {
				this.setState({
					isLoading: false,
					openWarning_login: true
				});
			}
		} else {
			if (
				this.state.admin_login_password === "" ||
				this.state.admin_login_passwordState === "error"
			) {
				this.setState({
					admin_login_passwordState: "error",
					admin_login_passwordErrMsg: "Password is required"
				});
			}
			if (
				this.state.admin_login_username === "" ||
				this.state.admin_login_usernameState === "error"
			) {
				this.setState({
					admin_login_usernameState: "error",
					admin_login_usernameErrMsg: "Username is required"
				});
			}
		}
	};

	enable_login() {
		if (
			this.state.admin_login_usernameState === "success" &&
			this.state.admin_login_passwordState === "success"
		) {
			return false;
		} else {
			return true;
		}
	}
	componentDidMount() {
		// we add a hidden class to the card and after 700 ms we delete it and the transition appears
		this.timeOutFunction_login = setTimeout(
			function() {
				this.setState({ cardAnimaton_login: "" });
			}.bind(this),
			700
		);
	}
	componentWillUnmount() {
		clearTimeout(this.timeOutFunction_login);
		this.timeOutFunction_login = null;
	}
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.container}>
				{this.state.alert}
				<GridContainer justify="space-between" className={classes.alignImage}>
					<Hidden smDown>
						<GridItem xs={12} sm={6} md={5}>
							<img
								display={{ xs: "none", md: "block" }}
								src={Robot}
								alt="..."
								className="img"
							/>
						</GridItem>
					</Hidden>
					<GridItem xs={12} sm={6} md={5}>
						<form>
							<Card login className={classes[this.state.cardAnimaton_login]}>
								<CardHeader
									className={`${classes.cardHeader} ${classes.textCenter}`}
									color="info"
								>
									<div className={classes.socialLine}>
										<h4 className={classes.cardTitle}>Login</h4>
									</div>
								</CardHeader>
								<CardBody>
									<CustomInput
										success={this.state.admin_login_usernameState === "success"}
										error={this.state.admin_login_usernameState === "error"}
										id="admin_login_username"
										name="admin_login_username"
										formControlProps={{
											fullWidth: true,
											className: classes.customFormControlClasses
										}}
										inputProps={{
											onChange: event =>
												this.login_change(
													event,
													"admin_login_username",
													"username_login"
												),
											type: "text",
											startAdornment: (
												<InputAdornment
													position="start"
													className={classes.inputAdornment}
												>
													<Person className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											placeholder: "Username*"
										}}
										helpText={
											this.state.admin_login_usernameState === "error"
												? this.state.admin_login_usernameErrMsg
												: ""
										}
									/>
									<CustomInput
										success={this.state.admin_login_passwordState === "success"}
										error={this.state.admin_login_passwordState === "error"}
										id="login_userpassword"
										name="Login_userpassword"
										formControlProps={{
											fullWidth: true,
											className: classes.customFormControlClasses
										}}
										inputProps={{
											onChange: event =>
												this.login_change(
													event,
													"admin_login_password",
													"password_login"
												),
											type: "password",
											startAdornment: (
												<InputAdornment
													position="start"
													className={classes.inputAdornment}
												>
													<Lock className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											placeholder: "Password*"
										}}
										helpText={
											this.state.admin_login_passwordState === "error"
												? this.state.admin_login_passwordErrMsg
												: ""
										}
									/>
									<p style={{ paddingTop: "0px" }}>* Required Fields</p>
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									<GridContainer justify="center">
										<div className={classes.textCenter}>
											<GridItem xs={12} sm={12} md={12}>
												<LoaderButton
													id="login_button"
													disabled={this.enable_login()}
													type="submit"
													onClick={this.LoginClick}
													isLoading={this.state.isLoading}
													text="Let's GO"
													loadingText="Logging in…"
												/>
											</GridItem>
											<GridItem xs={12} sm={12} md={12}>
												<br />
												<p>
													{" "}
													<Link
														to="/auth/ForgetPasswordPage"
														className={classes.a}
													>
														{" "}
														Forget Password
													</Link>
												</p>
											</GridItem>
										</div>
									</GridContainer>
								</CardFooter>
							</Card>
						</form>
					</GridItem>
				</GridContainer>
				{this.state.openWarning_login ? (
					<WarningAlert
						confirmBtnText="Try Again"
						errorMessage="Username or Password is invalid"
						title="Failed To Login"
					/>
				) : null}
			</div>
		);
	}
}

LoginPage.propTypes = {
	classes: PropTypes.object.isRequired
};

// connect this component to the store
const mapStateToProps = state => {
	return {
		user: state.user
	};
};
const mapDispatchToProps = dispatch => {
	return {
		getuserdata: user => {
			dispatch({
				type: "GET_USER_DATA",
				payload: user
			});
		}
	};
};

const withloginPageStyle = withStyles(loginPageStyle)(LoginPage);

export default connect(mapStateToProps, mapDispatchToProps)(withloginPageStyle);
