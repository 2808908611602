import React from "react";
import { Query } from "react-apollo";
import { ApolloConsumer } from "react-apollo";
import ScreenLoading from "../LoadingScreen.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// Import files
import ViewAlllogs from "./ViewAlllogs.jsx";
import Errorpage from "../Pages/errorPage.jsx";
import Datanotfound from "../DataNotFound.jsx";
// Queries and mutation
import ActivityListQuery from "../../Graphql/Querries/SystemLogsQuery";

const ActivityQuery = ({
	username = localStorage.getItem("username"),
	history,
	client
}) => (
	<Query query={ActivityListQuery} variables={{ username }}>
		{({ loading, data, error }) => {
			if (loading) {
				return (
					<GridContainer justify="center">
						<GridItem xs={10} sm={8} md={8}>
							<ScreenLoading />
						</GridItem>
					</GridContainer>
				);
			}
			if (error)
				return (
					<div>
						<Errorpage />
					</div>
				);

			return (
				<div>
					{data.getSystemLog.logs === null ? (
						<Datanotfound
							title={"No History Found"}
							message={
								"You will get this data if You Request your Employee to share their Employment Data with you"
							}
							button={false}
							link={"/employee/viewrequest"}
							buttontext={"Approve Document"}
						/>
					) : (
						<ViewAlllogs
							key={username}
							EmployerActivity={data.getSystemLog}
							history={history}
							client={client}
						/>
					)}
				</div>
			);
		}}
	</Query>
);

class Systemlogs extends React.Component {
	render() {
		return (
			<ApolloConsumer>
				{client => (
					<ActivityQuery history={this.props.history} client={client} />
				)}
			</ApolloConsumer>
		);
	}
}

export default Systemlogs;
