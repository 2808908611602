import gql from "graphql-tag";
const BlockEmployee = gql`
  mutation profileQuery(
    $username: String!
    $employee_username: String!
    $profile_status: String!
    $reason: String
  ) {
    employeeProfileAction(
      username: $username
      employee_username: $employee_username
      profile_status: $profile_status
      reason: $reason
    ) {
      message
    }
  }
`;
export default BlockEmployee;
