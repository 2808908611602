import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert"; // react component used to create sweet alerts
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon"; // To import Material ui icon
// @material-ui/icons
//images
import Robot from "assets/img/robot.png";

import Person from "@material-ui/icons/Person";
import PhonelinkLock from "@material-ui/icons/PhonelinkLock";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
// Custom components
import LoaderButton from "../../Custom-component/LoaderButton";
import CustomInput from "../../Custom-component/CustomInput/CustomInput.jsx";
// Styles
import loginPageStyle from "./loginPageStyle.jsx";
// File imports
import config from "../../config";
import WarningAlert from "../WarningAlert.jsx";
// validation
import validation from "../Validation/ProfileValidation.jsx";
// Encryption plugin
var sha256 = require("js-sha256");

class ForgetpasswordPage extends React.Component {
	constructor(props) {
		super(props);
		// we use this to make the card to appear after the page has been rendered
		this.state = {
			cardAnimaton_forgot: "cardHidden",
			showme: true,
			alert: null,
			forgetpassword_username: "",
			forgetpassword_usernameState: "",
			forgetpassword_newotp: "",
			forgetpassword_newotpState: "",
			forgetpassword_newpassword: "",
			forgetpassword_newpasswordState: "",
			forgetpassword_newpasswordConfirm: "",
			forgetpassword_newpasswordConfirmState: "",
			value: "60",
			isLoading: false,
			isLoading1: false,
			hidepassword_confirm_forgot: true,
			hidepassword_forgot: true,
			openWarning_forgetpassword: false,
			errorMessage: "",
			forgetpassword_usernameErrMsg: "",
			forgetpassword_newotpErrMsg: "",
			forgetpassword_newpasswordErrMsg: ""
		};
	}

	componentDidMount() {
		// we add a hidden class to the card and after 700 ms we delete it and the transition appears
		this.timeOutFunction_forgot = setTimeout(
			function() {
				this.setState({ cardAnimaton_forgot: "" });
			}.bind(this),
			700
		);
	}

	componentWillUnmount() {
		clearTimeout(this.timeOutFunction_forgot);
		this.timeOutFunction_forgot = null;
	}

	forgot_onchange(event, stateName, type) {
		switch (type) {
			case "username_forgot":
				var username_forgot_validation = validation.verifyUsername(
					event.target.value.trim(),
					stateName
				);
				if (!username_forgot_validation[stateName]) {
					this.setState({ [stateName + "State"]: "success" });
				} else {
					this.setState({
						[stateName + "State"]: "error",
						[stateName + "ErrMsg"]: username_forgot_validation[stateName]
					});
				}
				break;
			case "otp_forgot":
				var otp_forgot_validation = validation.verify_otp(
					event.target.value,
					stateName
				);
				if (!otp_forgot_validation[stateName]) {
					this.setState({ [stateName + "State"]: "success" });
				} else {
					this.setState({
						[stateName + "State"]: "error",
						[stateName + "ErrMsg"]: otp_forgot_validation[stateName]
					});
				}
				break;
			case "password_forgot":
				if (event.target.value) {
					var forgetpassword_validation = validation.password_validate_compare(
						event.target.value,
						stateName,
						this.state.forgetpassword_newpasswordConfirm
					);
					if (forgetpassword_validation === "error") {
						this.setState({
							[stateName + "State"]: "error",
							[stateName +
							"ErrMsg"]: "Password should contains at least one capital letter,small letter,integer,special character and length should greater than 8"
						});
					} else {
						if (forgetpassword_validation === "success_compare") {
							this.setState({
								[stateName + "ConfirmState"]: "success"
							});
						} else if (forgetpassword_validation === "error_compare") {
							this.setState({
								[stateName + "ConfirmState"]: "error"
							});
						}
						this.setState({
							[stateName + "State"]: "success"
						});
					}
				} else {
					this.setState({ [stateName + "ErrMsg"]: "Password is required" });
				}
				break;
			case "equalTo_forgot":
				if (
					validation.compare_password(
						event.target.value,
						this.state.forgetpassword_newpassword,
						stateName
					)
				) {
					this.setState({
						[stateName + "State"]: "success"
					});
				} else {
					this.setState({
						[stateName + "State"]: "error"
					});
				}
				break;
			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	}

	buttonenable_forgot() {
		if (this.state.forgetpassword_usernameState === "success") {
			return false;
		} else {
			if (this.state.forgetpassword_usernameState !== "success") {
				return true;
			}
		}
	}
	verifybutton_forgot() {
		if (
			this.state.forgetpassword_newotpState === "success" &&
			this.state.forgetpassword_newpasswordState === "success" &&
			this.state.forgetpassword_newpasswordConfirmState === "success"
		) {
			return false;
		} else {
			return true;
		}
	}

	OtpAlert_forgot = () => {
		this.setState({
			alert: (
				<SweetAlert
					style={{ display: "block", marginTop: "-200px", color: "black" }}
					title="OTP sent!"
					onConfirm={() => this.successhideAlert_forgot()}
					showConfirm={false}
				>
					Check your email to get OTP.
				</SweetAlert>
			)
		});
		setTimeout(this.otphidealert, 2000);
	};
	otphidealert = () => {
		this.setState({
			alert: null,
			showme: false
		});
	};
	// function to get OTP after giving username
	sendotp = async event => {
		event.preventDefault();
		if (!this.buttonenable_forgot()) {
			this.setState({ isLoading: true, openWarning_forgetpassword: false });

			const payload = {
				username: this.state.forgetpassword_username
			};
			var headers = {
				"Content-Type": "application/json"
			};
			try {
				await axios
					.post(
						config.Cognito.forgetpassword_url,
						payload,
						{ crossdomain: true },
						{ headers: headers }
					)
					.then(response => {
						if (response.data.data === null) {
							if (
								response.data.errors.error_code === "GBECO1002" ||
								response.data.errors.error_code === "GBECO1011"
							) {
								this.setState({
									isLoading: false,
									forgetpassword_usernameState: "error",
									forgetpassword_usernameErrMsg:
										"User name does not exist. Please provide valid username"
								});
							} else if (response.data.errors.error_code === "GBECO1013") {
								this.setState({
									isLoading: false,
									openWarning_forgetpassword: true,
									errorMessage:
										"Unable to send OTP. Maximum limit exceed. Please try after some time"
								});
							} else {
								this.setState({
									isLoading: false,
									openWarning_forgetpassword: true,
									errorMessage: response.data.errors.error_message
								});
							}
						} else {
							this.setState({
								isLoading: false,
								showme: false,
								value: "60"
							});
							this.OtpAlert_forgot();
							this._increase_forgot();
						}
					})
					.catch(err => {
						this.setState({
							isLoading: false,
							openWarning_forgetpassword: true,
							errorMessage:
								"We experiencing difficulties with connectivity of the application, Please try again later"
						});
					});
			} catch (e) {
				this.setState({
					isLoading: false,
					openWarning_forgetpassword: true,
					errorMessage:
						"We experiencing difficulties with connectivity of the application, Please try again later"
				});
			}
		} else {
			this.setState({
				forgetpassword_usernameState: "error",
				forgetpassword_usernameErrMsg: "Username is required"
			});
		}
	};
	_increase_forgot = () => {
		if (this.state.value > 0) {
			this.setState({
				value: this.state.value - 1
			});
			if (this.state.value !== 0) {
				setTimeout(this._increase_forgot.bind(this), 1000);
				return true;
			} else {
				clearInterval(this.interval);
				return false;
			}
		}
	};

	resendotp = async event => {
		this.setState({ isLoading: true, openWarning_forgetpassword: false });
		const payload = {
			username: this.state.forgetpassword_username
		};
		var headers = {
			"Content-Type": "application/json"
		};
		try {
			await axios
				.post(
					config.Cognito.forgetpassword_url,
					payload,
					{ crossdomain: true },
					{ headers: headers }
				)
				.then(response => {
					if (response.data.data === null) {
						if (
							response.data.errors.error_code === "GBECO1002" ||
							response.data.errors.error_code === "GBECO1011"
						) {
							this.setState({
								forgetpassword_usernameState: "error",
								forgetpassword_usernameErrMsg:
									"User name does not exist. Please provide valid username"
							});
						} else if (response.data.errors.error_code === "GBECO1013") {
							this.setState({
								errorMessage:
									"Unable to send OTP. Maximum limit exceed. Please try after some time",
								openWarning_forgetpassword: true
							});
						} else {
							this.setState({
								isLoading: false,
								errorMessage: response.data.errors.error_message,
								openWarning_forgetpassword: true
							});
						}
					} else {
						this.setState({
							isLoading: false
						});
						this.OtpAlert_forgot();
						this._increase_forgot();
					}
				})
				.catch(err => {
					this.setState({
						isLoading: false,
						openWarning_forgetpassword: true,
						errorMessage:
							"We experiencing difficulties with connectivity of the application, Please try again later"
					});
				});
		} catch (e) {
			this.setState({
				isLoading: false,
				openWarning_forgetpassword: true,
				errorMessage:
					"We experiencing difficulties with connectivity of the application, Please try again later"
			});
		}
	};
	resetpassword = async event => {
		event.preventDefault();
		if (!this.verifybutton_forgot()) {
			this.setState({ isLoading1: true, openWarning_forgetpassword: false });
			const resetpassword_payload = {
				username: this.state.forgetpassword_username,
				password: sha256(this.state.forgetpassword_newpassword),
				code: this.state.forgetpassword_newotp
			};
			var headers = {
				"Content-Type": "application/json"
			};
			try {
				await axios
					.post(
						config.Cognito.confirmForgotPassword_url,
						resetpassword_payload,
						{ crossdomain: true },
						{ headers: headers }
					)
					.then(response => {
						if (response.data.data === null) {
							if (response.data.errors.error_code === "GBECO1011") {
								this.setState({
									errorMessage:
										"User name not exists.Please provide valid username",
									isLoading1: false
								});
							} else if (response.data.errors.error_code === "GBECO1012") {
								this.setState({
									isLoading1: false,
									errorMessage: "Your OTP gets expired. Please resent your OTP"
								});
							} else if (response.data.errors.error_code === "GBECO1013") {
								this.setState({
									isLoading1: false,
									errorMessage:
										"Unable to change your password. Maximum limit exceed. Please try after some time"
								});
							} else {
								this.setState({
									isLoading1: false,
									errorMessage: response.data.errors.error_message
								});
							}
							this.setState({ openWarning_forgetpassword: true });
						} else {
							this.setState({ isLoading1: false });
							this.autoCloseAlert_forgot();
						}
					})
					.catch(err => {
						this.setState({
							isLoading1: false,
							openWarning_forgetpassword: true,
							errorMessage:
								"We experiencing difficulties with connectivity of the application, Please try again later"
						});
					});
			} catch (e) {
				this.setState({
					isLoading1: false,
					openWarning_forgetpassword: true,
					errorMessage:
						"We experiencing difficulties with connectivity of the application, Please try again later"
				});
			}
		} else {
			if (this.state.forgetpassword_newotpState !== "success") {
				this.setState({
					forgetpassword_newotpState: "error"
				});
				if (this.state.forgetpassword_newotp === "") {
					this.setState({
						forgetpassword_newotpErrMsg: "OTP is Required"
					});
				}
			}
			if (this.state.forgetpassword_newpasswordState !== "success") {
				this.setState({
					forgetpassword_newpasswordState: "error"
				});
				if (this.state.forgetpassword_newpassword === "") {
					this.setState({
						forgetpassword_newpasswordErrMsg: "New Password is Required"
					});
				}
			}
			if (this.state.forgetpassword_newpasswordConfirmState !== "success") {
				this.setState({
					forgetpassword_newpasswordConfirmState: "error"
				});
			}
		}
	};
	autoCloseAlert_forgot = () => {
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: "block", marginTop: "-100px", color: "#000000" }}
					title="Success !!"
					onConfirm={() => this.successhideAlert_forgot()}
					showConfirm={false}
				>
					<h4>"Your password has been successfully changed"</h4>
				</SweetAlert>
			)
		});
		setTimeout(this.successhideAlert_forgot, 2000);
	};
	successhideAlert_forgot = () => {
		this.setState({
			alert: null
		});
		this.props.history.push("/auth/login");
	};
	// hide/show password
	hidepassword_forgot = () => {
		this.setState({ hidepassword_forgot: !this.state.hidepassword_forgot });
	};
	// hide/show password
	hidepassword_confirm_forgot = () => {
		this.setState({
			hidepassword_confirm_forgot: !this.state.hidepassword_confirm_forgot
		});
	};
	render() {
		const { classes } = this.props;

		return (
			<div className={classes.container}>
				{this.state.alert}
				<GridContainer justify="space-between" className={classes.alignImage}>
					<Hidden smDown>
						<GridItem xs={12} sm={6} md={5} only={["md", "xl"]}>
							<img src={Robot} alt="..." className="img" />
						</GridItem>
					</Hidden>
					<GridItem xs={12} sm={6} md={5}>
						{/* //* Forget password username screeen */}
						{this.state.showme ? (
							<div>
								<form>
									<Card
										login
										className={classes[this.state.cardAnimaton_forgot]}
									>
										<CardHeader
											className={`${classes.cardHeader} ${classes.textCenter}`}
											color="info"
										>
											<div className={classes.socialLine}>
												<h4 className={classes.cardTitle}> Forget Password</h4>
											</div>
										</CardHeader>
										<CardBody>
											<CustomInput
												success={
													this.state.forgetpassword_usernameState === "success"
												}
												error={
													this.state.forgetpassword_usernameState === "error"
												}
												id="forgetpassword_username"
												formControlProps={{
													fullWidth: true,
													className: classes.customFormControlClasses
												}}
												inputProps={{
													onChange: event =>
														this.forgot_onchange(
															event,
															"forgetpassword_username",
															"username_forgot"
														),
													// onChange:this.handleInputChange,
													type: "text",
													startAdornment: (
														<InputAdornment
															position="start"
															className={classes.inputAdornment}
														>
															<Person className={classes.inputAdornmentIcon} />
														</InputAdornment>
													),
													placeholder: "Username*"
												}}
												helpText={
													this.state.forgetpassword_usernameState === "error"
														? this.state.forgetpassword_usernameErrMsg
														: ""
												}
											/>
										</CardBody>
										<CardFooter className={classes.textCenter}>
											<GridContainer justify="center">
												<GridItem xs={12} sm={10} md={10}>
													<LoaderButton
														id="forgetpassword_button"
														disabled={this.buttonenable_forgot()}
														onClick={this.sendotp}
														type="submit"
														isLoading={this.state.isLoading}
														text="Submit"
														loadingText="Submitting.."
													/>
												</GridItem>
											</GridContainer>
										</CardFooter>
									</Card>
								</form>
							</div>
						) : (
							<div>
								{/* //*Forget paasword New password screen */}
								<form>
									<Card
										login
										className={classes[this.state.cardAnimaton_forgot]}
									>
										<CardHeader
											className={`${classes.cardHeader} ${classes.textCenter}`}
											color="info"
										>
											<h4 className={classes.cardTitle}> Forget Password</h4>
											{/* <div className={classes.socialLine} /> */}
										</CardHeader>
										<CardBody>
											<GridContainer justify="center">
												<GridItem xs={12} sm={10} md={10}>
													{/* //*OTP Field  */}
													<CustomInput
														success={
															this.state.forgetpassword_newotpState ===
															"success"
														}
														error={
															this.state.forgetpassword_newotpState === "error"
														}
														id="newotp"
														formControlProps={{
															fullWidth: true,
															className: classes.customFormControlClasses
														}}
														inputProps={{
															onChange: event =>
																this.forgot_onchange(
																	event,
																	"forgetpassword_newotp",
																	"otp_forgot"
																),

															startAdornment: (
																<InputAdornment
																	position="start"
																	className={classes.inputAdornment}
																>
																	<PhonelinkLock
																		className={classes.inputAdornmentIcon}
																	/>
																</InputAdornment>
															),
															placeholder: "Enter your OTP code*"
														}}
														helpText={
															this.state.forgetpassword_newotpState === "error"
																? this.state.forgetpassword_newotpErrMsg
																: ""
														}
													/>
													{/* //*New  Password Field  */}
													<CustomInput
														success={
															this.state.forgetpassword_newpasswordState ===
															"success"
														}
														error={
															this.state.forgetpassword_newpasswordState ===
															"error"
														}
														id="newpassword"
														formControlProps={{
															fullWidth: true,
															className: classes.customFormControlClasses
														}}
														inputProps={{
															onChange: event =>
																this.forgot_onchange(
																	event,
																	"forgetpassword_newpassword",
																	"password_forgot"
																),
															// onChange:this.handleInputChange,
															type: this.state.hidepassword_forgot
																? "password"
																: "text",
															startAdornment: (
																<InputAdornment
																	position="start"
																	className={classes.inputAdornment}
																>
																	<Icon className={classes.inputAdornmentIcon}>
																		lock_outline
																	</Icon>
																</InputAdornment>
															),
															endAdornment: (
																<InputAdornment
																	style={{ marginRight: "-5px" }}
																	position="end"
																	className={classes.inputAdornment}
																>
																	{this.state.hidepassword_forgot ? (
																		<Tooltip
																			id="tooltip-top"
																			title="Show"
																			placement="bottom"
																			classes={{ tooltip: classes.tooltip }}
																		>
																			<Visibility
																				className={classes.inputAdornmentIcon}
																				style={{
																					color: "00acc1",
																					cursor: "pointer"
																				}}
																				onClick={this.hidepassword_forgot}
																			/>
																		</Tooltip>
																	) : (
																		<Tooltip
																			id="tooltip-top"
																			title="Hide"
																			placement="bottom"
																			classes={{ tooltip: classes.tooltip }}
																		>
																			<VisibilityOff
																				className={classes.inputAdornmentIcon}
																				style={{
																					color: "00acc1",
																					cursor: "pointer"
																				}}
																				onClick={this.hidepassword_forgot}
																			/>
																		</Tooltip>
																	)}
																</InputAdornment>
															),
															placeholder: "New Password*"
														}}
														helpText={
															this.state.forgetpassword_newpasswordState ===
															"error"
																? this.state.forgetpassword_newpasswordErrMsg
																: ""
														}
													/>

													{/* //*confirm Password Field  */}
													<CustomInput
														success={
															this.state
																.forgetpassword_newpasswordConfirmState ===
															"success"
														}
														error={
															this.state
																.forgetpassword_newpasswordConfirmState ===
															"error"
														}
														id="confirmpassword"
														formControlProps={{
															fullWidth: true,
															className: classes.customFormControlClasses
														}}
														inputProps={{
															onChange: event =>
																this.forgot_onchange(
																	event,
																	"forgetpassword_newpasswordConfirm",
																	"equalTo_forgot"
																),
															type: this.state.hidepassword_confirm_forgot
																? "password"
																: "text",
															startAdornment: (
																<InputAdornment
																	position="start"
																	className={classes.inputAdornment}
																>
																	<Icon className={classes.inputAdornmentIcon}>
																		lock_outline
																	</Icon>
																</InputAdornment>
															),
															endAdornment: (
																<InputAdornment
																	style={{ marginRight: "-5px" }}
																	position="end"
																	className={classes.inputAdornment}
																>
																	{this.state.hidepassword_confirm_forgot ? (
																		<Tooltip
																			id="tooltip-top"
																			title="Show"
																			placement="bottom"
																			classes={{ tooltip: classes.tooltip }}
																		>
																			<Visibility
																				className={classes.inputAdornmentIcon}
																				style={{
																					color: "00acc1",
																					cursor: "pointer"
																				}}
																				onClick={
																					this.hidepassword_confirm_forgot
																				}
																			/>
																		</Tooltip>
																	) : (
																		<Tooltip
																			id="tooltip-top"
																			title="Hide"
																			placement="bottom"
																			classes={{ tooltip: classes.tooltip }}
																		>
																			<VisibilityOff
																				className={classes.inputAdornmentIcon}
																				style={{
																					color: "00acc1",
																					cursor: "pointer"
																				}}
																				onClick={
																					this.hidepassword_confirm_forgot
																				}
																			/>
																		</Tooltip>
																	)}
																</InputAdornment>
															),
															placeholder: "Confirm Password*"
														}}
														helpText={
															this.state
																.forgetpassword_newpasswordConfirmState ===
															"error"
																? "Please provide same password"
																: ""
														}
													/>

													<div className={classes.textCenter}>
														<LoaderButton
															id="Forgetpassword_verifyotpbutton"
															type="submit"
															disabled={this.verifybutton_forgot()}
															onClick={this.resetpassword}
															isLoading={this.state.isLoading1}
															text="Verifying OTP"
															loadingText="Verifying..."
														/>
													</div>
												</GridItem>
											</GridContainer>
										</CardBody>
										<CardFooter className={classes.textCenter}>
											<GridContainer justify="center">
												<GridItem xs={12} sm={10} md={10}>
													<p>
														Please wait for {this.state.value} seconds to resend
														code...
													</p>
													<LoaderButton
														id="Forgetpassword_resendotpbutton"
														type="submit"
														disabled={this.state.value !== 0}
														onClick={
															this.state.value === 0 ? this.sendotp : null
														}
														isLoading={this.state.isLoading}
														text="Resend"
														loadingText="Resending..."
													/>
												</GridItem>
											</GridContainer>
										</CardFooter>
									</Card>
								</form>
							</div>
						)}
					</GridItem>
				</GridContainer>
				{this.state.openWarning_forgetpassword ? (
					<WarningAlert
						confirmBtnText="Try Again"
						errorMessage={this.state.errorMessage}
						title="Failed"
					/>
				) : null}
			</div>
		);
	}
}

ForgetpasswordPage.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(ForgetpasswordPage);
