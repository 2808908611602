import {
	cardTitle,
	infoColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const userProfileStyles = {
	cardTitle,
	...customCheckboxRadioSwitch,
	...sweetAlertStyle,
	checkboxAndRadioHorizontal: {
		position: "relative",
		display: "block",
		"&:first-child": {
			marginTop: "15px"
		},
		"&:not(:first-child)": {
			marginTop: "-14px"
		},
		"@media (max-width: 300px)": {
			marginTop: "10px "
		},
		marginTop: "40px",
		marginBottom: "0"
	},
	viewiconbutton: {
		color: "#ffffff",
		backgroundColor: infoColor,
		"&:hover ,&:focus": {
			color: "black"
		}
	},
	uniqueid_text: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		display: "block",
		paddingTop: "10px"
	},
	labelHorizontalRadioCheckbox: {
		paddingTop: "20px important",
		float: "left !important"
	},
	subtitle: {
		color: "#4c4949",
		fontWeight: "normal"
	},
	maindescription: {
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "13px"
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px",
		"& small": {
			fontSize: "80%",
			fontWeight: "400"
		}
	},
	subheading: {
		color: "#4c4949",
		fontWeight: "400"
	},
	cardSignup: {
		borderRadius: "6px",
		padding: "20px 0px",
		fontWeight: "400 !important"
	},
	cardBody: {
		textAlign: "left",
		fontSize: "0.9em"
	},
	cardheader: {
		textAlign: "left",
		fontWeight: "400",
		marginLeft: "20px",
		fontSize: "1.3em",
		color: "#635a5a"
	},
	cardsideHeader: {
		color: "#4a4a4a",
		fontWeight: "300"
	},
	cardCategory: {
		marginTop: "10px",
		color: "#999999 !important",
		textAlign: "center"
	},
	description: {
		color: "#999999"
	},
	updateProfileButton: {
		float: "right"
	},
	backProfileButton: {
		float: "left"
	},
	boldheading: {
		color: "#4c4949",
		fontWeight: "bolder",
		textAlign: "left",
		marginLeft: "20px"
	},
	subparagraph: {
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: "15px"
	},
	statusheading: {
		color: "#4c4949",
		fontWeight: "bolder",
		textAlign: "center"
	},
	mainheading: {
		color: "#4c4949",
		fontWeight: "bolder",
		textAlign: "left"
	},
	footerheading: {
		color: "#4c4949",
		fontWeight: "bolder",
		textAlign: "center"
	},
	inputAdornment: {
		marginRight: "18px",
		position: "relative"
	},
	inputAdornmentIcon: {
		color: "#555"
	},
	editbuttonStyle: {
		backgroundColor: "#fff",
		color: "#000",
		border: "none"
	},
	editiconStyle: {
		fontSize: "1.5em",
		cursor: "pointer"
	},
	savebuttonStyle: {
		cursor: "pointer",
		backgroundColor: infoColor,
		border: "1px solid #ffc90d",
		width: "80px",
		marginRight: "5px",
		borderRadius: "15px"
	},
	cancelbuttonStyle: {
		cursor: "pointer",
		backgroundColor: infoColor,
		color: "red"
	},
	approvestatusicon: {
		color: "green",
		fontSize: "3em"
		// backgroundColor:'#33DDFF',
	},
	rejectstatusicon: {
		color: "red",
		fontSize: "3em"
		// backgroundColor:'#33DDFF',
	},
	pendingstatusicon: {
		color: "yellow",
		fontSize: "3em"
		// backgroundColor:'#33DDFF',
	}
};
export default userProfileStyles;
