import React from "react";
// import LoadingScreen from "react-loading-screen";
import ReactLoaderQuotes from "react-loader-quotes";

function ScreenLoading() {
	return (
		<ReactLoaderQuotes
			loading={true}
			speed={1.5}
			quotes={[`Loading..`, `Please Wait..`]}
			backgroundColorLoader="#ffc90d"
			colorTitle="#000000"
			fontSizeTitle="25px"
		/>
	);
}
export default ScreenLoading;
