import gql from "graphql-tag";
const BlockEmployer = gql`
  mutation profileQuery(
    $username: String!
    $employer_username: String!
    $profile_status: String!
    $reason: String
  ) {
    employerProfileAction(
      username: $username
      employer_username: $employer_username
      profile_status: $profile_status
      reason: $reason
    ) {
      message
    }
  }
`;
export default BlockEmployer;
