import { ApolloConsumer } from "react-apollo";
import React from "react";
import { Query } from "react-apollo";
import ScreenLoading from "../LoadingScreen.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

//Graphql Queries and mutations
import ListAllEmployeeQuery from "../../Graphql/Querries/ListAllEmployeeQuery";
// Import files
import DataNotFound from "../DataNotFound";
import AllEmployeeList from "./AllEmployeeList.jsx";
import Errorpage from "../Pages/errorPage";

//Retrieve all employees onboarded into our application
const ListEmployeesquery = ({
	username = localStorage.getItem("username"),
	history,
	client,
	status
}) => (
	<Query query={ListAllEmployeeQuery} variables={{ username }}>
		{({ loading, data, error }) => {
			if (loading) {
				return (
					<GridContainer justify="center">
						<GridItem xs={10} sm={8} md={8}>
							<ScreenLoading />
						</GridItem>
					</GridContainer>
				);
			}
			if (error) {
				return (
					<div>
						<Errorpage />
					</div>
				);
			}
			return (
				<div>
					{data.getAllEmployees === null ? (
						<DataNotFound
							message={"There is no Employee Onboarded into VerifyMate"}
							backbutton={false}
						/>
					) : (
						<AllEmployeeList
							key={data.getAllEmployees}
							AllEmployees={data.getAllEmployees}
							history={history}
							client={client}
							status={status}
						/>
					)}
				</div>
			);
		}}
	</Query>
);

class AllEmployee extends React.Component {
	render() {
		return (
			<ApolloConsumer>
				{client => (
					<ListEmployeesquery
						history={this.props.history}
						client={client}
						status={this.props.location.state}
					/>
				)}
			</ApolloConsumer>
		);
	}
}

export default AllEmployee;
