import React, { Component } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// custom components
import Button from "Custom-component/CustomButtons/Button.jsx";

//customly edited components
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
class DataNotFound extends Component {
	render() {
		const { classes, message, backbutton } = this.props;
		return (
			<div>
				<GridContainer justify="center">
					<GridItem xs={12} sm={10} md={8}>
						<Card className={classes.cardSignup}>
							<h2 className={classes.cardTitle}>
								<b>NO DATA FOUND</b>
							</h2>
							<CardBody>
								<GridContainer justify="center">
									<GridItem xs={11} sm={12} md={10}>
										<h3 style={{ textAlign: "center" }}>{message}</h3>
									</GridItem>
								</GridContainer>
								{backbutton ? (
									<GridContainer justify="center">
										<Button
											color="info"
											style={{ textAlign: "center" }}
											onClick={this.props.handleBack}
										>
											Back
										</Button>
									</GridContainer>
								) : null}
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		);
	}
}

export default withStyles(registerPageStyle)(DataNotFound);
