// input validator
var validator = require("validator");
var errors = {};

// function for name and address validation
function verifyname(value, statename) {
	var validation = { alphapets: /^[a-zA-Z\s.]*$/ };
	if (validator.isEmpty(value.trim())) {
		errors[statename] = "Name is required";
	} else if (!validation.alphapets.test(value.trim())) {
		errors[statename] =
			"Only alphabets,special character include .(dot) are allowed";
	} else if (!validator.isLength(value.trim(), 3, 30)) {
		errors[statename] = "Name must be 3 to 30 characters long";
	} else {
		errors = {};
	}
	return errors;
}
// function for name and address validation
function verifyaddress(value, statename) {
	// address validation
	var Addressvalidation = {
		alphanumeric: /^[a-zA-Z0-9\s,/()#*,^&+.'_-]*$/
	};
	if (validator.isEmpty(value.trim())) {
		errors[statename] = "Address is required";
	} else if (!Addressvalidation.alphanumeric.test(value.trim())) {
		errors[statename] =
			"Only alphabets,numbers,special characters includes /*()^#,+&.'_- are allowed";
	} else if (!validator.isLength(value.trim(), 4, 150)) {
		errors[statename] = "Address must be 4 to 150 characters long";
	} else {
		errors = {};
	}
	return errors;
}
// function that returns true if value is email, otherwise set error state
function verifyEmail(value, statename) {
	if (validator.isEmpty(value)) {
		errors[statename] = "Email is required";
	} else if (!validator.isEmail(value)) {
		errors[statename] = "Invalid email address";
	} else {
		errors = {};
	}
	return errors;
}

// function that verifies that entering the otp is valid or not
function verify_otp(value, statename) {
	var numberRex = new RegExp("^[0-9]+$");
	if (validator.isEmpty(value)) {
		errors[statename] = "OTP is required";
	} else if (!numberRex.test(value)) {
		errors[statename] = "Only numbers are allowed";
	} else if (!(value.length === 6)) {
		errors[statename] = "OTP must be 6 characters long";
	} else {
		errors = {};
	}
	return errors;
}
// Validation for password
function password_validate(value, statename) {
	var pattern = new RegExp(
		/(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+-.])(?!.*\s)[0-9a-zA-Z!@#$%^&+-.]*$/
	);
	if (validator.isEmpty(value)) {
		errors[statename] = "Password is required";
	} else if (!pattern.test(value)) {
		errors[statename] =
			"Password should contains at least one capital letter,small letter,integer,special character and length should greater than 8";
	} else {
		errors = {};
	}
	return errors;
}
// function that verifies username
function verifyUsername(value, statename) {
	var validation = { alphanumeric: /^([a-zA-Z])+[A-Za-z0-9-_]*$/ };
	if (validator.isEmpty(value)) {
		errors[statename] = "Username is required";
	} else if (!validation.alphanumeric.test(value)) {
		errors[statename] =
			"Only alphabets,numbers and some special characters including - (hyphen) _ (underscore) are allowed";
	} else if (!validator.isLength(value, 3, 30)) {
		errors[statename] = "Username must be 3 to 30 characters long";
	} else {
		errors = {};
	}
	return errors;
}

// function for duration field validation
function verifyDuration(value, statename) {
	var numberRex = new RegExp("^[0-9]+$");
	if (validator.isEmpty(value)) {
		errors[statename] = "Duration is required";
	} else if (!numberRex.test(value)) {
		errors[statename] = "Only numbers are allowed";
	} else {
		errors = {};
	}
	return errors;
}
// compare password and confirm password fields
function compare_password(string1, string2, statename) {
	if (string1 === string2) {
		return true;
	}
	return false;
}
// password validation for new password
function password_validate_compare(value, statename, compare_value) {
	var passwordRegex = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+-.])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/;
	var finalResult = passwordRegex.test(value);
	if (finalResult && compare_value && compare_password(value, compare_value)) {
		return "success_compare";
	} else if (
		finalResult &&
		compare_value &&
		!compare_password(value, compare_value)
	) {
		return "error_compare";
	} else if (!finalResult) {
		return "error";
	} else {
		return "success";
	}
}
// function that verifies if a string has a given length or not accept numeric in the starting and only numeric
function verifyTextarea(value, statename) {
	var validation = {
		alphapetsnumeric: /^([a-zA-Z])+[A-Za-z0-9!@#$%^&+-._ ]*$/
	};
	if (validator.isEmpty(value)) {
		errors[statename] = "Reason is required";
	} else if (!validation.alphapetsnumeric.test(value)) {
		errors[statename] =
			"Only alphabets,numbers,special characters includes !@#$%^&+-._ are allowed";
	} else if (value.length < 4) {
		errors[statename] =
			"You have entered insufficient characters please enter atleast 4 characters";
	} else {
		errors = {};
	}
	return errors;
}
//validation for company name
function verifycompanyname(value, statename) {
	var verifyLengthvalidation = {
		alphapetsnumeric: /^(?!\s)(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s,()#*,@&+.'_-]*$/
	};
	if (validator.isEmpty(value)) {
		errors[statename] = "Organization name is required";
	} else if (!verifyLengthvalidation.alphapetsnumeric.test(value)) {
		errors[statename] =
			"Only alphabets,numbers,special characters includes ()#*,-@&.'_+ are allowed";
	} else if (!validator.isLength(value, 3, 50)) {
		errors[statename] = "Organization name must be 3 to 50 characters long";
	} else {
		errors = {};
	}

	return errors;
}
//validations for designation,department
function verifydesignation(value, statename) {
	var designationvalidation = {
		alphanumeric: /^([a-zA-Z])+[A-Za-z0-9-._ ]*$/
	};
	if (validator.isEmpty(value)) {
		errors[statename] = "Designation is required";
	} else if (!designationvalidation.alphanumeric.test(value)) {
		errors[statename] =
			"Only alphabets,numbers,special characters including - _ . are allowed";
	} else if (!validator.isLength(value, 4, 30)) {
		errors[statename] = "Designation  must be 4 to 30 characters long";
	} else {
		errors = {};
	}
	return errors;
}
// validation for department
function verifydepartment(value, statename) {
	var Departmentvalidation = {
		alphanumeric: /^([a-zA-Z])+[A-Za-z0-9-._ ]*$/
	};
	if (validator.isEmpty(value)) {
		errors[statename] = "Department is required";
	} else if (!Departmentvalidation.alphanumeric.test(value)) {
		errors[statename] =
			"Only alphabets,numbers,special characters including - _ . are allowed";
	} else if (!validator.isLength(value, 2, 30)) {
		errors[statename] = "Department name must be 2 to 30 characters long";
	} else {
		errors = {};
	}
	return errors;
}
// function that verifies if a string has a given length or not
function verifyempId(value, statename) {
	var verifyalphanumericvalidation = {
		alphanumeric: /^[A-Za-z0-9-_]*$/
	};
	if (validator.isEmpty(value)) {
		errors[statename] = "Employee ID is required";
	} else if (!verifyalphanumericvalidation.alphanumeric.test(value)) {
		errors[statename] =
			"Only alphabets,numbers and some special characters including -(hyphen) _(underscore) are allowed";
	} else if (!validator.isLength(value, 4, 15)) {
		errors[statename] = "Employee ID must be 4 to 15 characters long";
	} else {
		errors = {};
	}
	return errors;
}
function verifydocumentname(value, statename) {
	var verifyalphanumericvalidation = {
		alphanumeric: /^([a-zA-Z])+[A-Za-z0-9-._ ]*$/
	};
	if (validator.isEmpty(value)) {
		errors[statename] = "Document name is required";
	} else if (!verifyalphanumericvalidation.alphanumeric.test(value)) {
		errors[statename] =
			"Only alphabets,numbers and some special characters including -(hyphen) _(underscore).(dot) are allowed";
	} else if (!validator.isLength(value, 4, 25)) {
		errors[statename] = "Document name must be 4 to 25 characters long";
	} else {
		errors = {};
	}
	return errors;
}
function verifyIdentityNumber(value, statename, type) {
	if (type === "pan") {
		var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
		if (validator.isEmpty(value)) {
			errors[statename] = "PAN is required";
		} else if (!regpan.test(value)) {
			errors[statename] = "Please provide valid PAN number";
		} else {
			errors = {};
		}
	} else {
		var dinvalidation = /^([0-9]){8}?$/;
		if (validator.isEmpty(value)) {
			errors = {};
		} else if (!dinvalidation.test(value)) {
			errors[statename] = "Please provide valid DIN number";
		} else {
			errors = {};
		}
	}
	return errors;
}
export default {
	verifyaddress,
	verifyname,
	verifyIdentityNumber,
	verifyEmail,
	verify_otp,
	verifyDuration,
	password_validate,
	verifyUsername,
	compare_password,
	password_validate_compare,
	verifyTextarea,
	verifycompanyname,
	verifyempId,
	verifydepartment,
	verifydesignation,
	verifydocumentname
};
