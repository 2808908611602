import gql from "graphql-tag";
const ListAllEmployerQuery = gql`
  query employerQuery($username: String) {
    getAllEmployers(username: $username) {
      employer {
        username
        name
        aadhar_number
        aadhar_verified
        email
        phone_number
        PAN
        DIN
        hyperledger_id
        company_name
        company_address
        gender
        birthdate
        profile_status
        reason
        created_at
        created_by
        updated_at
        updated_by
        __typename
      }
    }
  }
`;
export default ListAllEmployerQuery;
