// ##############################
//   authenticatedlayoutstyle
// #############################

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import sweetAlertStyle from "../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

const changepasswordStyle = theme => ({
	wrapper: {
		position: "relative",
		top: "0",
		height: "100vh",
		"&:after": {
			display: "table",
			clear: "both",
			content: '" "'
		}
	},
	inputAdornment: {
		// marginRight: '10px',
		position: "relative"
	},
	inputAdornmentIcon: {
		color: "#ffc90d"
	},
	formControl: {
		margin: "0px",
		position: "relative",
		paddingTop: "27px"
	},
	submitbutton: {
		float: "right"
	},
	closebutton: {
		float: "left"
	},
	...modalStyle(theme),
	...sweetAlertStyle
});

export default changepasswordStyle;
