import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon"; // To import Material ui icon
import InputAdornment from "@material-ui/core/InputAdornment";
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import LockOpen from "@material-ui/icons/LockOpen";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// Custom components
import CustomInput from "Custom-component/CustomInput/CustomInput.jsx";
import LoaderButton from "../Custom-component/LoaderButton";
// file imports
import changepasswordStyle from "./changepasswordStyle.jsx";
import config from "../config.js";
import WarningAlert from "../Container/WarningAlert.jsx";
import history from "../history";

// validation
import validation from "../Container/Validation/ProfileValidation.jsx";
// Encryption plugin
var sha256 = require("js-sha256");

class Changepassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			oldpassword: "",
			oldpasswordState: "",
			oldpasswordErrMsg: "",
			newpasswordErrMsg: "",
			newpassword: "",
			newpasswordState: "",
			newpasswordConfirm: "",
			newpasswordConfirmState: "",
			hidepassword_confirm_changepassword: false,
			hidepassword_changepassword: false,
			errorMessage: "",
			alert: null,
			closemodal: false,
			openWarning_changepassword: false,
			isLoading: false
		};
	}
	// modal close button onclick function
	handleClose = () => {
		this.setState({
			alert: null
		});
		this.props.close(this.state.closemodal);
	};
	// onchange function for input fields
	change_changepassword(event, stateName, type, stateNameEqualTo) {
		switch (type) {
			case "password_old":
				var old_password_validation = validation.password_validate(
					event.target.value,
					stateName
				);
				if (!old_password_validation[stateName]) {
					this.setState({ [stateName + "State"]: "success" });
				} else {
					this.setState({
						[stateName + "State"]: "error",
						[stateName + "ErrMsg"]: old_password_validation[stateName]
					});
				}
				break;
			case "password_new":
				if (event.target.value) {
					var change_newpassword_validation = validation.password_validate_compare(
						event.target.value,
						stateName,
						this.state.newpasswordConfirm
					);
					if (change_newpassword_validation === "error") {
						this.setState({
							[stateName + "State"]: "error",
							[stateName +
							"ErrMsg"]: "Password should contains at least one capital letter,small letter,integer,special character and length should greater than 8"
						});
					} else {
						if (change_newpassword_validation === "success_compare") {
							this.setState({
								[stateName + "ConfirmState"]: "success"
							});
						} else if (change_newpassword_validation === "error_compare") {
							this.setState({
								[stateName + "ConfirmState"]: "error"
							});
						}
						this.setState({
							[stateName + "State"]: "success"
						});
					}
				} else {
					this.setState({ [stateName + "ErrMsg"]: "Password is required" });
				}
				break;
			case "equalTo_changepassword":
				if (
					validation.compare_password(
						event.target.value,
						this.state.newpassword,
						stateName
					)
				) {
					this.setState({
						[stateName + "State"]: "success"
					});
				} else {
					this.setState({
						[stateName + "State"]: "error"
					});
				}
				break;
			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	}
	// to enable_changepassword a change password button
	enable_changepassword = () => {
		if (
			this.state.oldpasswordState === "success" &&
			this.state.newpasswordState === "success" &&
			this.state.newpasswordConfirmState === "success"
		) {
			this.handlechangepassword();
		} else {
			if (this.state.oldpassword === "") {
				this.setState({
					oldpasswordState: "error",
					oldpasswordErrMsg: "Old password is required"
				});
			}
			if (this.state.newpassword === "") {
				this.setState({
					newpasswordState: "error",
					newpasswordErrMsg: "New password is required"
				});
			}
			if (this.state.newpasswordConfirm === "") {
				this.setState({
					newpasswordConfirmState: "error",
					newpasswordConfirmErrMsg: "Confirm password is required"
				});
			}
		}
	};
	//success alert
	passwordchage_alert = () => {
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: "block", marginTop: "-100px", color: "#000000" }}
					title="Password changed successfully"
					onConfirm={() => this.handleClose()}
					onCancel={() => this.handleClose()}
					confirmBtnCssClass={
						this.props.classes.button + " " + this.props.classes.success
					}
				/>
			)
		});
	};
	// hide/show password
	hidepassword_changepassword = () => {
		this.setState({
			hidepassword_changepassword: !this.state.hidepassword_changepassword
		});
	};
	// hide/show password
	hidepassword_confirm_changepassword = () => {
		this.setState({
			hidepassword_confirm_changepassword: !this.state
				.hidepassword_confirm_changepassword
		});
	};
	// when button is enabled and onclicking the button
	handlechangepassword = async event => {
		if (
			this.state.oldpasswordState === "success" &&
			this.state.newpasswordState === "success" &&
			this.state.newpasswordConfirmState === "success"
		) {
			try {
				this.setState({ openWarning_changepassword: false, isLoading: true });
				const changepassword_payload = {
					newpassword: sha256(this.state.newpassword),
					oldpassword: sha256(this.state.oldpassword)
				};
				var headers = {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("accessToken")
				};
				axios
					.post(config.Cognito.changepassword_url, changepassword_payload, {
						headers: headers
					})
					.then(response => {
						this.setState({ isLoading: false });
						if (response.data.data === null) {
							if (response.data.errors.error_code === "GBECO1013") {
								this.setState({
									errorMessage:
										"Unable to change your password. Maximum limit exceed. Please try after some time"
								});
							} else {
								this.setState({
									errorMessage: response.data.errors.error_message
								});
							}

							this.setState({ openWarning_changepassword: true });
						} else {
							this.setState({
								shownewpasswordConfirm: false,
								oldpasswordState: "",
								newpasswordState: "",
								newpasswordConfirmState: ""
							});
							this.passwordchage_alert();
						}
					})
					.catch(err => {
						if (err.response.status === 401) {
							localStorage.clear();
							history.push("/auth/login");
						} else {
							this.setState({
								errorMessage:
									"We experiencing difficulties with connectivity of the application, Please try again later",
								openWarning_changepassword: true,
								isLoading: false
							});
						}
					});
			} catch (e) {
				this.setState({
					isLoading: false,
					errorMessage:
						"We experiencing difficulties with connectivity of the application, Please try again later",
					openWarning_changepassword: true
				});
			}
		}
	};
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.wrapper}>
				<Dialog
					classes={{
						root: classes.center + " " + classes.modalRoot,
						paper: classes.modal
					}}
					open={true}
					keepMounted
					onClose={() => this.handleClose()}
					aria-labelledby="notice-modal-slide-title"
					aria-describedby="notice-modal-slide-description"
				>
					<DialogTitle
						id="notice-modal-slide-title"
						disableTypography
						className={classes.modalHeader}
					>
						<h4 className={classes.modalTitle}>
							<b>Change Password</b>
						</h4>
					</DialogTitle>
					<DialogContent
						id="notice-modal-slide-description"
						className={classes.modalBody}
					>
						<CustomInput
							success={this.state.oldpasswordState === "success"}
							error={this.state.oldpasswordState === "error"}
							id="changepassword_oldpassword"
							name="changepassword_oldpassword"
							formControlProps={{
								fullWidth: true,
								className: classes.formControl
							}}
							inputProps={{
								type: "password",
								placeholder: "Old Password*",
								onChange: event =>
									this.change_changepassword(
										event,
										"oldpassword",
										"password_old",
										3
									),
								startAdornment: (
									<InputAdornment
										position="start"
										className={classes.inputAdornment}
									>
										<LockOpen className={classes.inputAdornmentIcon} />
									</InputAdornment>
								)
							}}
							helpText={
								this.state.oldpasswordState === "error"
									? this.state.oldpasswordErrMsg
									: ""
							}
						/>
						<CustomInput
							success={this.state.newpasswordState === "success"}
							error={this.state.newpasswordState === "error"}
							id="changepassword_newpassword"
							name="changepassword_newpassword"
							formControlProps={{
								fullWidth: true,
								className: classes.formControl
							}}
							inputProps={{
								type: this.state.hidepassword_changepassword
									? "text"
									: "password",
								placeholder: "New Password*",
								onChange: event =>
									this.change_changepassword(
										event,
										"newpassword",
										"password_new",
										3
									),
								startAdornment: (
									<InputAdornment
										position="start"
										className={classes.inputAdornment}
									>
										<Icon className={classes.inputAdornmentIcon}>
											lock_outline
										</Icon>
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment
										style={{ marginRight: "-5px" }}
										position="end"
										className={classes.inputAdornment}
									>
										{this.state.hidepassword_changepassword ? (
											<Tooltip
												id="tooltip-top"
												title="Show"
												placement="bottom"
												classes={{ tooltip: classes.tooltip }}
											>
												<Visibility
													className={classes.inputAdornmentIcon}
													style={{
														color: "00acc1",
														cursor: "pointer"
													}}
													onClick={this.hidepassword_changepassword}
												/>
											</Tooltip>
										) : (
											<Tooltip
												id="tooltip-top"
												title="Hide"
												placement="bottom"
												classes={{ tooltip: classes.tooltip }}
											>
												<VisibilityOff
													className={classes.inputAdornmentIcon}
													style={{
														color: "00acc1",
														cursor: "pointer"
													}}
													onClick={this.hidepassword_changepassword}
												/>
											</Tooltip>
										)}
									</InputAdornment>
								)
							}}
							helpText={
								this.state.newpasswordState === "error"
									? this.state.newpasswordErrMsg
									: ""
							}
						/>
						<CustomInput
							success={this.state.newpasswordConfirmState === "success"}
							error={this.state.newpasswordConfirmState === "error"}
							id="changepassword_newpasswordConfirm"
							name="changepassword_newpasswordConfirm"
							formControlProps={{
								fullWidth: true,
								className: classes.formControl
							}}
							inputProps={{
								type: this.state.hidepassword_confirm_changepassword
									? "text"
									: "password",
								placeholder: "Confirm Password*",
								onChange: event =>
									this.change_changepassword(
										event,
										"newpasswordConfirm",
										"equalTo_changepassword",
										"newpassword"
									),
								startAdornment: (
									<InputAdornment
										position="start"
										className={classes.inputAdornment}
									>
										<Icon className={classes.inputAdornmentIcon}>
											lock_outline
										</Icon>
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment
										style={{ marginRight: "-5px" }}
										position="end"
										className={classes.inputAdornment}
									>
										{this.state.hidepassword_confirm_changepassword ? (
											<Tooltip
												id="tooltip-top"
												title="Show"
												placement="bottom"
												classes={{ tooltip: classes.tooltip }}
											>
												<Visibility
													className={classes.inputAdornmentIcon}
													style={{
														color: "00acc1",
														cursor: "pointer"
													}}
													onClick={this.hidepassword_confirm_changepassword}
												/>
											</Tooltip>
										) : (
											<Tooltip
												id="tooltip-top"
												title="Hide"
												placement="bottom"
												classes={{ tooltip: classes.tooltip }}
											>
												<VisibilityOff
													className={classes.inputAdornmentIcon}
													style={{
														color: "00acc1",
														cursor: "pointer"
													}}
													onClick={this.hidepassword_confirm_changepassword}
												/>
											</Tooltip>
										)}
									</InputAdornment>
								)
							}}
							helpText={
								this.state.newpasswordConfirmState === "error"
									? "Please provide same password"
									: ""
							}
						/>
					</DialogContent>
					<DialogActions>
						<GridContainer justify="flex-start">
							<Button
								onClick={() => this.handleClose()}
								round
								className={classes.closebutton}
							>
								Close
							</Button>
						</GridContainer>
						<GridContainer justify="flex-end">
							{/* <Button
								disabled={this.enable_changepassword()}
								onClick={this.handlechangepassword}
								color="info"
								round
								className={classes.submitbutton}
							>
								Change Password
							</Button> */}
							<LoaderButton
								round
								type="submit"
								onClick={() => this.enable_changepassword()}
								isLoading={this.state.isLoading}
								text="Change Password"
								loadingText="Changing..."
							/>
						</GridContainer>
					</DialogActions>
				</Dialog>
				{this.state.alert}
				{this.state.openWarning_changepassword ? (
					<WarningAlert
						confirmBtnText="Try Again"
						errorMessage={this.state.errorMessage}
						title="Failed to change"
					/>
				) : null}
			</div>
		);
	}
}

Changepassword.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(changepasswordStyle)(Changepassword);
