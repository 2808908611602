import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
const dashboardstyle = {
	...dashboardStyle,
	card: {
		maxWidth: 345,
		boxShadow:
			"0 12px 20px -10px rgba(255, 201, 13, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 201, 13, 0.2)"
	},
	cardIconTitle: {
		color: "#000 !important",
		marginTop: "10px",
		marginBottom: "0px"
	},
	center: {
		textAlign: "center !important"
	},
	justifyContentCenter: {
		justifyContent: "center"
	},
	title: {
		color: "#3C4858",

		textDecoration: "none",
		fontWeight: "300",
		marginTop: "30px",
		marginBottom: "25px",
		minHeight: "32px",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1"
		}
	},

	cardTitle: {
		textAlign: "center",
		marginTop: "15px",
		marginBottom: "3px",
		minHeight: "auto",
		"& a": {
			marginTop: ".625rem",
			marginBottom: "0.75rem",
			minHeight: "auto"
		}
	},
	infoArea: {
		maxWidth: "560px !important",
		margin: "0 auto",
		padding: "0px"
	},
	description: {
		color: "#999999",
		/* overflow: hidden; */
		fontSize: "14px",
		marginTop: "0px",
		width: "60%"
	},
	updateProfileButton: {
		float: "right"
	},
	backProfileButton: {
		float: "left"
	},
	successicons: {
		color: "green",
		fontSize: "4em"
	},
	rejecticons: {
		color: "red",
		fontSize: "4em"
	},
	cardStyle: {
		padding: "50px"
	},
	cardCategory: {
		color: "#4c4949",
		// fontSize: "larger",
		marginTop: "12px"
	},
	cssLabel: {
		"&$cssFocused": {
			color: "#3C4858"
		}
	},
	cssFocused: {},
	underline: {
		"&:after": {
			borderBottom: "2px solid #ffc90d"
		}
	},
	nameclass: { marginBottom: "-10px" }
};
export default dashboardstyle;
