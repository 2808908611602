import React from 'react';
import Dashboardquery from './Dashboardquery';
import { ApolloConsumer } from 'react-apollo';

class Dashboard extends React.Component {
	render() {
		return (
			<ApolloConsumer>{client => <Dashboardquery history={this.props.history} client={client} />}</ApolloConsumer>
		);
	}
}

export default Dashboard;
