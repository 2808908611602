import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Custom components
import PagesHeader from "Custom-component/Header/PagesHeader.jsx";
import Footer from "Custom-component/Footer/Footer.jsx";

// styles
import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";
// File imports
//import bgImage from "assets/img/background.jpg";
import pagesRoutes from "routes/unauthenticatedroutes.jsx";

class Unauthenticatedlayout extends React.Component {
	componentDidMount() {
		document.body.style.overflow = "unset";
	}
	render() {
		const { classes, ...rest } = this.props;
		return (
			<div>
				<PagesHeader {...rest} />
				<div className={classes.wrapper} ref="wrapper">
					<div
						className={classes.fullPage}
						//	style={{ backgroundImage: "url(" + bgImage + ")" }}
						style={{ backgroundColor: "black" }}
					>
						<Switch>
							{pagesRoutes.map((prop, key) => {
								if (prop.collapse) {
									return null;
								}
								if (prop.redirect) {
									return (
										<Redirect from={prop.path} to={prop.pathTo} key={key} />
									);
								}
								return (
									<Route
										path={prop.path}
										component={prop.component}
										key={key}
									/>
								);
							})}
						</Switch>
						<Footer white />
					</div>
				</div>
			</div>
		);
	}
}

Unauthenticatedlayout.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Unauthenticatedlayout);
